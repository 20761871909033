// @flow
import request from './ApiClient';
import { snakeCaseKeys } from './DataFormatter';
import type { SerializedApiObject } from 'types/ApiTypes';
import type { Event } from 'types/EventTypes';

const create = (data: $Shape<Event & { userEventsAttributes?: Array<{ user_id: number }>}>): Promise<SerializedApiObject<Event>> => {
  return request({
    url: '/events',
    method: 'POST',
    data: snakeCaseKeys(data)
  });
};

const EventService = {
  create
};

export default EventService;
