// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import ActionIconButton from 'components/ActionIconButton';
import { UserDecorator, EmailDecorator } from 'services/decorators';
import { palette } from 'constants/theme';
import type { Peer, UserPreference } from 'types/UserTypes';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  listItem: {
    cursor: 'pointer',
    padding: theme.spacing(1, 10, 1, 1),
    '&:hover': {
      backgroundColor: 'rgba(0, 6, 46, 0.05)'
    }
  }
}));

const PeerListItemSecondaryText = ({ jobTitle, companyName, topSkill }: { jobTitle: ?string, companyName: ?string, topSkill: ?string }): React$Node => {
  return (
    <>
      <div style={{ color: palette.black }}>
        {UserDecorator.careerHeadline(jobTitle, companyName)}
      </div>
      {topSkill && (
        <div>
          <span>Good at: </span>
          <span style={{ color: palette.blue }}>{topSkill}</span>
        </div>
      )}
    </>
  )
}

const PeerList = ({ peers }: { peers: Array<Peer> }): React$Node => {
  const classes = useStyles();

  return (
    <List className={classes.root} disablePadding>
      {peers.map(peer => {
        const { id, avatarUrl, name, jobTitle, companyName, email, linkedinUrl, topSkill } = peer;

        return (
          <ListItem key={id} className={classes.listItem} disableGutters>
            <ListItemAvatar>
              <Avatar src={avatarUrl} alt={name} />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>{name}</Typography>}
              secondary={<PeerListItemSecondaryText jobTitle={jobTitle} companyName={companyName} topSkill={topSkill} />}
            />
            <ListItemSecondaryAction>
              {linkedinUrl && <ActionIconButton href={linkedinUrl} target='_blank' icon='linkedin' />}
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  );
};

export default PeerList;
