// @flow
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Check from '@material-ui/icons/Check'

type Props = {
  activeStep: number,
  steps: Array<string>
}

const useStepperStyles = makeStyles(theme => ({
  root: {
    background: 'none',
    padding: 0
  }
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#5d8aa3',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#5d8aa3',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#5d8aa3',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#5d8aa3',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

const MaterialStepper = ({ activeStep, steps }: Props): React$Node => {
  const classes = useStepperStyles();

  return (
    <Stepper alternativeLabel className={classes.root} activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map(step => (
        <Step key={step}>
          <StepLabel StepIconComponent={QontoStepIcon}>{step}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default MaterialStepper;
