// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';

import { UpcomingEventsProvider } from 'components/UpcomingEvents';
import NetworkPanel from 'components/NetworkPanel';
import FullPageBox from 'components/FullPageBox';
import TodoStepper from './TodoStepper';
import UpcomingEventsSection from './UpcomingEventsSection';
import Assessments from './Assessments';
import ResourceCenter from './ResourceCenter';

const useStyles = makeStyles(theme => ({
  gridRoot: {
    height: '100%'
  },
  leftSection: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4)
    }
  },
  rightSection: {
    boxShadow: '0px 2px 26px 0px rgba(0, 0, 0, .10)',
  },
  scrollable: {
    height: '100%',
    overflowY: 'scroll'
  },
  eventSection: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

const Dashboard = (): React$Node => {
  const classes = useStyles();

  return (
    <UpcomingEventsProvider>
      <FullPageBox withNav>
        <Grid container className={classes.gridRoot}>
          <Grid container item md={8} xs={12} justify='center' alignContent='flex-start' className={classes.scrollable}>
            <Grid item container xs={12} spacing={4} className={`${classes.leftSection} ${classes.scrollable}`}>
              <Grid item xs={12}>
                <Typography variant='h2' align='center'>Your Leader Cohort</Typography>
              </Grid>
              <Grid item xs={12}>
                <TodoStepper />
              </Grid>
              <Grid container item xs={12}>
                <Assessments />
              </Grid>
              <Grid container item xs={12}>
                <UpcomingEventsSection />
              </Grid>
              <Grid item xs={12}>
                <ResourceCenter />
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid container item md={4} xs={3} className={`${classes.rightSection} ${classes.scrollable}`}>
              <NetworkPanel />
            </Grid>
          </Hidden>
        </Grid>
      </FullPageBox>
    </UpcomingEventsProvider>
  )
}

export default Dashboard;
