// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FullPageBox from 'components/FullPageBox'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import isEmpty from 'lodash/isEmpty'

import Nav from 'components/Nav'
import Basics from '../Onboarding/Basics'
import Goals from '../Onboarding/Goals'
import Challenges from '../Onboarding/Challenges'
import Strengths from '../Onboarding/Strengths'
import { UserService } from 'services/api'
import { useSessionUser } from 'hooks';

const useStyles = makeStyles(theme => ({
  gridRoot: {
    height: '100%'
  },
  content: {
    flex: 1,
    height: '100%'
  },
  scrollable: {
    height: '100%',
    overflowY: 'scroll'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}))

const Profile = (): React$Node => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [data, setData] = useState(null)
  const [user, setUser] = useState(null)
  const [errors, setErrors] = useState(undefined)
  const sessionUser = useSessionUser();

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (sessionUser) {
      UserService.fetch(sessionUser.id).then(response => {
        const userAttributes = response.data.attributes
        setUser(userAttributes)
        setData([
          {
            name: userAttributes.name,
            linkedinUrl: userAttributes.linkedinUrl,
            city: userAttributes.city,
            state: userAttributes.state,
            jobTitle: userAttributes.jobTitle,
            companyName: userAttributes.companyName
          },
          userAttributes.preferences?.goals,
          userAttributes.preferences?.challenges,
          userAttributes.preferences?.strengths
        ])
      })
    }
  }, [])

  const handleSubmit = () => {
    if (!data || !data[value]) return;

    const updateData = data[value];
    if (value === 0) {
      // Update basic user data
      if (validateBasics(updateData)) {
        UserService.update(sessionUser.id, updateData);
      } else {
        return null
      }
    } else if (value === 1) {
      // Update user goals
      UserService.updatePreferences(sessionUser.id, 'goals', updateData)
    } else if (value === 2) {
      // Update user challenges
      UserService.updatePreferences(sessionUser.id, 'challenges', updateData)
    } else if (value === 3) {
      // Update user strengths
      UserService.updatePreferences(sessionUser.id, 'strengths', updateData)
    }

    setErrors(undefined);
  };

  const validateBasics = (updateData: *) => {
    if (!updateData) return false;

    const newErrors = {};
    const attributes = ['name', 'city', 'state', 'jobTitle', 'companyName'];

    attributes.forEach(attribute => {
      if (isEmpty(updateData[attribute])) {
        newErrors[attribute] = 'This field is required';
      }
    });

    if (isEmpty(newErrors)) {
      return true
    }

    setErrors(newErrors);
    return false;
  };

  const setSubData = (step, update) => {
    let currentData = data ? [...data] : []
    currentData[step] = update
    setData(currentData)
  }

  const getStepContent = (step) => {
    if (!data) return null;
    const defaultData = data[step];

    switch (step) {
      case 0:
        return <Basics defaultData={defaultData} onUpdate={update => setSubData(step, update)} errors={errors} />
      case 1:
        return <Goals defaultData={defaultData} onUpdate={update => setSubData(step, update)}/>
      case 2:
        return <Challenges defaultData={defaultData} onUpdate={update => setSubData(step, update)}/>
      case 3:
        return <Strengths defaultData={defaultData} onUpdate={update => setSubData(step, update)}/>
      default:
        return null
    }
  };

  if (!user || !data) return null

  return (
    <FullPageBox withNav>
      <Grid container className={classes.gridRoot}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="The Basics" />
          <Tab label="My Goals" />
          <Tab label="My Challenges" />
          <Tab label="My Strengths" />
        </Tabs>
        <Grid container item wrap="nowrap" className={classes.content} direction="column">
          <Grid item style={{ flex: 1, overflow: 'scroll', padding: 20 }}>
            {getStepContent(value)}
          </Grid>
          <Grid item style={{ flex: 0, position: 'sticky', bottom: 0, zIndex: 1}}>
            <Grid container justify="space-around" style={{ padding: '40px 20px' }}>
              <Button
                size="large"
                onClick={handleSubmit}
                style={{ flex: 1, maxWidth: 300 }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPageBox>
  )
}

export default Profile
