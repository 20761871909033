// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import { palette } from 'constants/theme';

function withCardStyle<Config: {}>(
  Component: React.AbstractComponent<Config>
): React.AbstractComponent<Config> {
  return withStyles({
    root: {
      boxShadow: '0px 2px 23px 0px #E4E6E9',
      borderRadius: 6,
      backgroundColor: palette.white
    }
  })(Component);
}

export default withCardStyle;
