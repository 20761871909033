// @flow
import request from './ApiClient';

import type { SerializedApiObject } from 'types/ApiTypes';
import type { User } from 'types/UserTypes';

const fetchUserByInvitation = (token: string): Promise<SerializedApiObject<User>> => {
  return request({
    url: `/auth/sign_up`,
    method: 'GET',
    params: { token }
  })
};

type RegistrationData = {
  password: string,
  password_confirmation: string,
  token: string
};

const update = (data: $Shape<User> | RegistrationData): Promise<SerializedApiObject<User>> => {
  return request({
    url: '/auth',
    method: 'PATCH',
    data
  });
};

const RegistrationService = {
  fetchUserByInvitation,
  update
};

export default RegistrationService;
