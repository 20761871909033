// @flow
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import * as ROUTES from 'constants/routes';

const ForgotPasswordLink = (): React$Node => (
  <Link
    component={RouterLink}
    to={ROUTES.FORGOT_PASSWORD}
    color='inherit'
  >
    Forgot Password
  </Link>
);

export default ForgotPasswordLink;
