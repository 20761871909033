// @flow
import React, { useState, useEffect } from 'react';

import Stepper from 'components/Stepper';
import { useSessionUser } from 'hooks';

const steps = [
  'Take the LAQ',
  'Book a Coaching Session',
  'Join the Slack Community',
  'Request a Mentor Meeting'
];

const TodoStepper = (): React$Node => {
  const [activeStep, setActiveStep] = useState(0);
  const { trackedActions } = useSessionUser();

  useEffect(() => {
    if (trackedActions.bookedCoachingSession) {
      setActiveStep(2);
    } else if (trackedActions.completedLaq) {
      setActiveStep(1);
    }
  }, [trackedActions]);

  return (
    <Stepper activeStep={activeStep} steps={steps} />
  );
};

export default TodoStepper;
