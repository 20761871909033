// @flow
import React from 'react';

import { states } from 'constants/selectOptions';
import SimpleSelect from './SimpleSelect';

import type { SimpleSelectProps } from './SimpleSelect';

const StateSelect = (props: SimpleSelectProps): React$Node => {
  return (
    <SimpleSelect
      {...props}
      id={props.id || 'state'}
      name={props.name || 'state'}
      label={props.label || 'State'}
      options={states}
    />
  );
};

export default StateSelect;
