// @flow
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { SessionContext } from 'components/Session';
import AuthenticationPage from 'components/AuthenticationPage';
import ForgotPasswordLink from 'components/ForgotPasswordLink';

const SignIn = (): React$Node => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn, errors, setErrors } = useContext(SessionContext);
  const history = useHistory();

  const onSubmit = () => {
    signIn(email, password)
  };

  const updateForm = (e, callback) => {
    setErrors(undefined);
    callback(e.target.value);
  };

  return (
    <AuthenticationPage onSubmit={onSubmit}>
      <Grid item xs={12}>
        <TextField
          value={email}
          label='Email'
          error={!!errors?.credentials}
          onChange={e => updateForm(e, setEmail)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={password}
          type='password'
          label='Password'
          error={!!errors?.credentials}
          helperText={errors?.credentials}
          onChange={e => updateForm(e, setPassword)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          size='large'
          type='submit'
        >
          Login
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align='center'
          variant='subtitle1'
          color='textSecondary'
        >
          <ForgotPasswordLink />
        </Typography>
      </Grid>
    </AuthenticationPage>
  );
};

export default SignIn;
