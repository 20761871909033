// @flow
import request from './ApiClient';
import type { SerializedApiCollection } from 'types/ApiTypes';
import type { SlackChannel } from 'types/SlackChannelTypes';

const list = (params?: ?{ limit?: number }): Promise<SerializedApiCollection<SlackChannel>> => {
  return request({
    url: '/slack_channels',
    method: 'GET',
    params
  });
};

const SlackChannelService = {
  list
};

export default SlackChannelService;
