// @flow
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';

import SlackChannelPreviewList from './SlackChannelPreviewList';
import NetworkMentorPreviewList from './NetworkMentorPreviewList';
import NetworkPeerPreviewList from './NetworkPeerPreviewList';

import { palette } from 'constants/theme';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    backgroundColor: palette.white
  },
  accordion: {
    boxShadow: 'none'
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: 0
  }
}));

const StyledAccordionSummary = withStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    margin: '0 !important' // need important here to override difficult nested changes when expanded
  }
}))(AccordionSummary);

const NetworkPanel = (): React$Node => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>Join the Conversation</Typography>
        </Grid>
        <Grid item xs={12}>
          <SlackChannelPreviewList />
        </Grid>
        <Grid item xs={12}>
          <Accordion className={classes.accordion} defaultExpanded>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>{`Mentors available in your network`}</Typography>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <NetworkMentorPreviewList />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion className={classes.accordion} defaultExpanded>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>{`Connect with your peers`}</Typography>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <NetworkPeerPreviewList />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NetworkPanel;
