// @flow
import React from 'react';
import Box from '@material-ui/core/Box';

import Nav from 'components/Nav';
import { height } from 'constants/theme';

const FullPageBox = (props: *): React$Node => {
  const { withNav, ...rest } = props;
  const defaults = { width: '100vw', height: '100vh' };

  if (withNav) {
    return (
      <Box { ...defaults }>
        <Nav />
        <Box
          width='100%'
          style={{ height: `calc(100% - ${height.navbar}` }}
          {...rest}
        />
      </Box>
    )
  }

  return (
    <Box
      { ...defaults }
      {...rest}
    />
  );
};

export default FullPageBox;
