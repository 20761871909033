// @flow
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

type DefaultData = *;

type Props = {
  defaultData: ?DefaultData,
  onUpdate: DefaultData => void
};

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(4)
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const Challenges = ({ defaultData, onUpdate }: Props): React$Node => {
  const classes = useStyles()
  const [data, setData] = useState(defaultData || {
    'Hiring and building effective teams': false,
    'Developing talent in my org': false,
    'Defining or enhancing my leadership style': false,
    'Influencing others more effectively': false,
    'Navigating internal politics': false,
    'Defining the optimal structure for my org': false,
    'Working cross-functionally with effectiveness': false,
    'Building relationships with my colleagues': false,
    'Implementing optimal technology solutions': false,
    'Planning for long term vision and roadmap': false,
    'Leading transformation in the company': false
  })
  const [error, setError] = useState(undefined)
  const limitReached = Object.values(data).filter(d => d).length >= 3;

  const handleChange = e => {
    if (limitReached && e.target.checked) return;

    setError(undefined)
    setData(state => ({
      ...state,
      [e.target.name]: e.target.checked
    }))
  }

  // Pass updates to data back up to the parent component
  useEffect(() => {
    onUpdate(data)
  }, [data])

  return (
    <div>
      <Container>
        <form>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={10}>
              <Typography variant="h3">Your top challenges</Typography>
              <Typography>Select up to 3 areas that you want to improve</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {Object.keys(data).sort().map(label => (
                    <FormControlLabel
                      key={label}
                      control={<Checkbox checked={data[label]} onChange={handleChange} name={label} />}
                      label={label}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  )
}

export default Challenges
