// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import FullPageBox from 'components/FullPageBox';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
}));

const TermsAndConditions = (): React$Node => {
  const classes = useStyles();

  return (
    <FullPageBox withNav overflow='scroll'>
    <Container className={classes.root} maxWidth='lg'>
      <Typography className={classes.marginBottom}><b>Odyssey Terms of Use</b></Typography>
      <Typography className={classes.marginBottom}>These terms of use (“Terms”) between Heidrick and Struggles, Inc (“Heidrick”) and you (“you” or “your”) govern your use of the Odyssey platform (“Odyssey”), which provides relevant content, coaching, mentorship and networking opportunities and is intended for use by consumers.</Typography>
      <Typography className={classes.marginBottom}>Please read these Terms carefully before accepting them and using Odyssey.</Typography>
      <Typography className={classes.marginBottom}>1. Agreement
      YOU ACCEPT THESE TERMS BY CLICKING “I AGREE”, OR BY USING ODYSSEY OR BY CONTINUING TO USE ODYSSEY EVEN AFTER A CHANGE HAS BEEN MADE TO THESE TERMS. YOU EXPRESSLY AGREE AND ACKNOWLEDGE THAT YOU ARE SUBJECT TO, BOUND BY, AND WILL COMPLY WITH THESE TERMS. BY USING ODYSSEY, YOU AGREE TO THESE TERMS; IF YOU DO NOT AGREE, DO NOT USE ODYSSEY. THESE TERMS ARE A LEGALLY BINDING AGREEMENT BETWEEN YOU AND HEIDRICK (EACH A “PARTY,” AND COLLECTIVELY THE “PARTIES”). </Typography>
      <Typography className={classes.marginBottom}>If you have any questions concerning these Terms, please contact Heidrick at Odyssey@heidrick.com.</Typography>
      <Typography className={classes.marginBottom}>Heidrick reserves the right to change these Terms, at any time, in its sole discretion, by posting the amended terms on this page and any associated links, which shall automatically be effective. Please review these Terms carefully before your first and every subsequent use of Odyssey, as your continued use of Odyssey constitutes your acceptance of the amended terms.</Typography>
      <Typography className={classes.marginBottom}>Heidrick will collect, use, store and otherwise process your personal information during your use of Odyssey. Heidrick is committed to maintaining the privacy of the information that you provide to Heidrick. Your use of Odyssey is subject to the Odyssey Privacy Notice and any other terms and conditions presented to you. You can review the Odyssey Privacy Notice setting out how we process your personal information <a href="/privacy-policy" target="_blank">by clicking here</a>.</Typography>
      <Typography className={classes.marginBottom}>2. Acceptable use</Typography>
      <Typography className={classes.marginBottom}>Heidrick will assign a username and password for each registered user. You are solely responsible for protecting your username and password with the same degree of care that a reasonable person uses to protect his or her other confidential information. You may not disclose your password to any third party without the prior written consent of Heidrick, or for any unauthorized purpose.</Typography>
      <Typography className={classes.marginBottom}>You shall not use, and shall not permit any third party to use, Odyssey to: (a) modify, disassemble, decompile, prepare derivative works of, reverse engineer or otherwise attempt to gain access to the source code of any component of Odyssey; (b) sell, rent, assign, transfer, sublicense, publish, distribute, disclose, display or otherwise commercially exploit or make available any component of Odyssey (or copies thereof) to others; (c) use Odyssey in a way that abuses, disables, interferes with, or disrupts Odyssey or any person or technology; (d) engage in activity that is illegal, tortious, fraudulent, false, or misleading; (e) build or benchmark a competitive product or service, or copy any features, functions, or graphics of Odyssey; or (f) use Odyssey on any device you do not own or control.</Typography>
      <Typography className={classes.marginBottom}>You represent and warrant that all of the information submitted, input or uploaded by you into Odyssey, including without limitation your registration information, survey data, any communications made and any transactions you make on Odyssey (“Information”): (i) will be accurate and complete and will not be fraudulent, fictitious, counterfeit, defamatory, libelous, threatening, harassing, misappropriated, stolen or otherwise unlawful or illicit; (ii) shall not infringe the intellectual property rights or other proprietary rights of any third party, including without limitation copyrights, patents, trademarks, trade secrets or rights of publicity or privacy; (iii) shall not violate any applicable law, statute, ordinance, or regulation (“Law”); and (iv) shall not contain any viruses, Easter eggs, kill switches, disabling devices, Trojan horses, worms, time bombs, bots, or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data or personal information. You will promptly notify Heidrick of any changes to your Information.</Typography>
      <Typography className={classes.marginBottom}>You will comply at all times with all applicable Law. You acknowledge that you are solely responsible for ensuring that the manner in which you transmit and receive information, and use Odyssey, complies with all Law.</Typography>
      <Typography className={classes.marginBottom}>You shall notify Heidrick immediately if you become aware of any unauthorized use of your username or password or any other known or suspected breach of security or misuse of Odyssey. In the event it is determined you no longer require access to Odyssey, you must immediately discontinue use of Odyssey.</Typography>
      <Typography className={classes.marginBottom}>3. Odyssey content</Typography>
      <Typography className={classes.marginBottom}>Where Odyssey contains links to other sites and resources provided by third parties, these links are provided for your information and convenience only.  Heidrick has no control over, and is not responsible for, the contents of those sites or resources. Heidrick reserves the right to withdraw linking permission without notice. </Typography>
      <Typography className={classes.marginBottom}>The content on Odyssey, including compensation data, is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on Odyssey.</Typography>
      <Typography className={classes.marginBottom}>Although we make reasonable efforts to update the information on Odyssey, we make no representations, warranties or guarantees, whether express or implied, that the content on Odyssey is accurate, complete or up to date.</Typography>
      <Typography className={classes.marginBottom}>Any rights not expressly granted through these Terms are reserved.</Typography>
      <Typography className={classes.marginBottom}>4. Termination</Typography>
      <Typography className={classes.marginBottom}>At any time and with or without cause, Heidrick may immediately discontinue providing or limit access to Odyssey. Heidrick may, in its sole discretion, at any time terminate these Terms, or any or all rights and privileges granted to you, and Heidrick may also suspend your account or refuse to provide you access to Odyssey. In the event you are in material breach of these Terms, Heidrick may refuse you any current or future use of Odyssey without notice. Heidrick may also terminate these Terms to comply with applicable Law for the lawful operation of Odyssey provided by Heidrick. Heidrick shall not be liable to you or any third party for such suspension or termination.</Typography>
      <Typography className={classes.marginBottom}>5. Intellectual property rights</Typography>
      <Typography className={classes.marginBottom}>For purposes of these Terms, “intellectual property rights” means any copyright, patent, trade secret, trade dress, trademark, rights in get-up, goodwill, rights in design, technology, artwork, computer software (including source code), database, and similar or equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any media now known or hereafter invented, in any part of the world. You agree to refrain from any action that would diminish or call such rights into question.</Typography>
      <Typography className={classes.marginBottom}>You acknowledge and agree that Heidrick or its licensors own all legal right, title and interest in and to all aspects of Odyssey, and any improved, updated, upgraded, modified, customized, or additional parts thereof, including, but not limited to, graphics, “look and feel”, user interface, scripts and software used to implement Odyssey, and any software or documents provided to you as part of or in connection with Odyssey, including, without limitation, all intellectual property rights that exist therein, whether registered or not, and wherever in the world they may exist.</Typography>
      <Typography className={classes.marginBottom}>All content and materials that comprise Odyssey, including, without limitation, its design, text, graphics, photographs, illustrations, images, icons, and other materials, and information (“Content”), are protected by copyright under U.S. and international copyright laws and treaties, and are the exclusive property of Heidrick (including, without limitation, any intellectual property rights therein). You shall not remove any copyright notices or proprietary restrictions. No portion of Odyssey may be reproduced in any form or by any means, except as expressly permitted in these Terms. Any unauthorized copying of Odyssey or failure to comply with these Terms will result in automatic termination and you agree it will constitute immediate and irreparable harm to Heidrick, its affiliates and its licensors for which monetary damages would be an inadequate remedy, and that injunctive relief will be an appropriate remedy.</Typography>
      <Typography className={classes.marginBottom}>All trademarks, service marks, trade names and trade dress displayed on Odyssey are protected in the U.S. and internationally and are either owned or used under license by Heidrick. The unauthorized use of any Heidrick trademark is strictly prohibited, and nothing contained herein or on Odyssey may be construed as granting, by implication, estoppels, or otherwise, any right or license to use any trademark. All structure, organization, and code of Odyssey are the valuable trade secrets and confidential information of Heidrick or its licensors.</Typography>
      <Typography className={classes.marginBottom}>Subject to your compliance with these Terms at all times, Heidrick hereby grants you a limited, personal, non-transferable, non-sublicensable, non-exclusive, revocable, right to use Odyssey in object form for the express purposes set forth herein and for your personal use only (and not for commercial use), and this use will not result in the transfer of any ownership or other right, title or interest in or to any of the proprietary technology from Heidrick or its licensors to you or any other party.</Typography>
      <Typography className={classes.marginBottom}>You hereby grant Heidrick a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, non-exclusive, sublicensable right to access, use, disclose, distribute, reproduce, modify, adapt, publish, translate, transform, display, and commercialize the Information and any other data submitted, input or uploaded by you into Odyssey for the following purposes: (a) to operate Odyssey, including, without limitation, to perform all of its features and functions; (b) to further develop and improve Odyssey and other of Heidrick’s products and services; (c) to monitor the performance of Odyssey; and (d) to aggregate and anonymize the Information and then use, share, and commercialize this output for any business purpose as determined by Heidrick in its sole discretion, including, without limitation, to build models, algorithms, learnings or intelligence and develop, license, market, and sell, directly or indirectly, products and services.</Typography>
      <Typography className={classes.marginBottom}>You further understand and agree that Odyssey is not intended as a storage repository for Information.  Heidrick has no responsibility or liability for any loss of Information.</Typography>
      <Typography className={classes.marginBottom}>Any submissions by you (e.g., comments, questions, suggestions, materials (“Feedback”)) through any communication whatsoever will be treated as both non-confidential and non-proprietary.  You hereby assign all right, title, and interest in, and Heidrick is free to use, without any notice, attribution or compensation to you, any ideas, know-how, concepts, techniques, or other intellectual property and proprietary rights contained or embodied in the Feedback, for any purpose whatsoever. You understand and agree that Heidrick is not obligated to use, display, reproduce, or distribute any such ideas, know-how, concepts, or techniques contained in the Feedback, and you have no right to compel such use, display, reproduction, or distribution.</Typography>

      <Typography className={classes.marginBottom}>6. Indemnification</Typography>
      <Typography className={classes.marginBottom}>You agree to indemnify, defend and hold harmless Heidrick and its affiliates for and from any loss or damages, including reasonable attorneys' fees, arising from or relating to: (a) your use of Odyssey (or any information available therein), including any alleged or actual violation of any law directly or indirectly arising from such use; (b) any breach or alleged breach by you of these Terms; and (c) the misuse or misappropriation of your Information.</Typography>
      <Typography className={classes.marginBottom}>7. Disclaimer of warranty; limitation of liability</Typography>
      <Typography className={classes.marginBottom}>YOU ACKNOWLEDGE THAT YOUR USE OF ODYSSEY IS ENTIRELY AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, HEIDRICK DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR LACK OF VIRUSES. WITHOUT LIMITING THE FOREGOING, YOU ACKNOWLEDGE AND AGREE THAT (I) HEIDRICK DOES NOT WARRANT THAT ODYSSEY SHALL BE ERROR-FREE OR OPERATE WITHOUT INTERRUPTION, AND ODYSSEY IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND; (II) HEIDRICK DOES NOT AND CANNOT CONTROL THE FLOW OF DATA AND INFORMATION THROUGH THE INTERNET, AND SUCH FLOW DEPENDS ON THE PERFORMANCE OF THIRD PARTIES WHOSE ACTIONS OR INACTIONS MAY PRODUCE SITUATIONS IN WHICH CONNECTIONS TO THE INTERNET (OR PORTIONS THEREOF) ARE IMPAIRED OR DISRUPTED AND FOR WHICH HEIDRICK IS NOT LIABLE; (III) HEIDRICK DOES NOT WARRANT THAT ANY CONTENT PROVIDED VIA ODYSSEY IS COMPLETE OR ACCURATE.</Typography>
      <Typography className={classes.marginBottom}>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL HEIDRICK OR ANY OF ITS AFFILIATES BE LIABLE TO YOU FOR MONETARY DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, INDIRECT, PUNITIVE OR EXEMPLARY DAMAGES, LOST PROFITS, LOST DATA OR THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES, HOWEVER CAUSED, AS A RESULT OF YOUR USE OF OR RELIANCE ON ODYSSEY, WHETHER FOR BREACH OF WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</Typography>
      <Typography className={classes.marginBottom}>NOTWITHSTANDING ANY LOSSES THAT YOU MAY INCUR AND TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE LIABILITY OF HEIDRICK, ITS AFFILIATES, and its and their respective officers, directors, employees, agents, successors, assignees and licensors, UNDER ANY PROVISION OF THESE TERMS, OR OTHERWISE CONNECTED TO THESE TERMS, AND YOUR SOLE AND EXCLUSIVE REMEDIES FOR ALL OF THE FOREGOING SHALL BE LIMITED TO THE GREATER OF (I) WHERE APPLICABLE, THE AMOUNT ACTUALLY PAID BY YOU FOR ODYSSEY’S PREMIUM SERVICES DURING THE 12 MONTH PERIOD IMMEDIATELY PRECEDING THE DATE ON THE EVENT GIVING RISE TO THE CLAIM AGAINST HEIDRICK AROSE OR (II) USD $10.00.</Typography>
      <Typography className={classes.marginBottom}>8. Miscellaneous provisions</Typography>
      <Typography className={classes.marginBottom}>Confidentiality: You shall hold Heidrick’s Confidential Information in confidence and shall not disclose Confidential Information to any third party without Heidrick’s prior written consent. “Confidential Information” means any information disclosed by Heidrick that is not generally known to the public or, by its nature, should be reasonably considered confidential. You acknowledge and agree that a breach of this clause would cause irreparable harm and that Heidrick shall be entitled to seek equitable relief from such breach.</Typography>

      <Typography className={classes.marginBottom}>Equitable Remedies: Both parties acknowledge and agree that monetary damages and other remedies at law may not provide an adequate remedy in the event of a breach of these Terms. Therefore, in addition to any other remedies that may be available, the non-breaching party shall be entitled to seek injunctive relief or specific performance or other equitable remedies.</Typography>
      <Typography className={classes.marginBottom}>Cumulative remedies: No remedy or election hereunder shall be deemed exclusive but shall, wherever possible, be cumulative with all other remedies at law or in equity.</Typography>
      <Typography className={classes.marginBottom}>Force Majeure: Heidrick shall not be liable for failure or delay in performing obligations set forth in these Terms, nor shall be deemed in breach of its obligations, if such failure or delay is due to natural disasters or any other causes reasonably beyond the control of Heidrick.</Typography>
      <Typography className={classes.marginBottom}>Assignment successors and assigns: These Terms and the rights granted by Heidrick to you hereunder may not be assigned or transferred by you without the prior written consent of Heidrick. Heidrick may assign these Terms without obtaining your consent.</Typography>
      <Typography className={classes.marginBottom}>Severability: If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of these Terms will continue in full force and effect.</Typography>
      <Typography className={classes.marginBottom}>Headings: The section headings contained in these Terms are included for convenience only, and shall not limit or otherwise affect these Terms.</Typography>
      <Typography className={classes.marginBottom}>Waiver: No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, and no single or partial exercise thereof shall preclude any other or further exercise thereof or the exercise of any other right, power or privilege hereunder.</Typography>
      <Typography className={classes.marginBottom}>Entire agreement: These Terms, and any other documents incorporated by reference herein, constitute the entire understanding and agreement, and supersedes any and all prior or contemporaneous representations, understandings and agreements, between the parties with respect to the subject matter of these Terms.</Typography>
      <Typography className={classes.marginBottom}>Choice of law and jurisdiction: These Terms shall be governed and interpreted in accordance with English law. You and we both agree to the exclusive jurisdiction of the courts of England.</Typography>
    </Container>
  </FullPageBox>
  );
};

export default TermsAndConditions;
