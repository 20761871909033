// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FullPageBox from 'components/FullPageBox'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import isEmpty from 'lodash/isEmpty'

import Nav from 'components/Nav'
import Basics from '../Onboarding/Basics'
import Goals from '../Onboarding/Goals'
import Challenges from '../Onboarding/Challenges'
import Strengths from '../Onboarding/Strengths'
import { UserService } from 'services/api'
import { useSessionUser } from 'hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  }
}))

const Contact = (): React$Node => {
  const classes = useStyles()

  return (
    <FullPageBox withNav>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Typography variant='h2' gutterBottom>
            Contact Us
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item style={{ width: '100%', maxWidth: 680, textAlign: 'center' }}>
              <Typography>
                For questions, comments, or concerns, please email us at <b>odyssey@heidrick.com</b>. Someone on our team will get back to you as soon as we can.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPageBox>
  )
}

export default Contact
