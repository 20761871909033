// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AuthenticationPage from 'components/AuthenticationPage';
import { PasswordService } from 'services/api';

const SuccessMessage = withStyles(theme => ({
  root: {
    color: theme.palette.success.main
  }
}))(Typography);

const ForgotPassword = (): React$Node => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(undefined);

  const onSubmit = () => {
    PasswordService.create(email)
      .then(response => {
        setSuccessMessage(response.message);
      })
      .catch(response => {
        setError(response.data.errors[0]);
      });
  };

  const updateForm = (e, callback) => {
    setError(undefined);
    callback(e.target.value);
  };

  return (
    <AuthenticationPage onSubmit={onSubmit}>
      <Grid item xs={12}>
        <TextField
          value={email}
          label='Email'
          error={!!error}
          helperText={error}
          onChange={e => updateForm(e, setEmail)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        {successMessage ? (
          <SuccessMessage variant='subtitle2'>
            {successMessage}
          </SuccessMessage>
        ) : (
          <Button
            fullWidth
            size='large'
            type='submit'
            disabled={!!successMessage}
          >
            Send Reset Password Email
          </Button>
        )}
      </Grid>
    </AuthenticationPage>
  )
};

export default ForgotPassword;
