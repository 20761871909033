// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Modal from 'components/Modal';
import { CompanySizeSelect, IndustrySelect } from 'components/Select';
import type { ModalProps } from 'components/Modal';
import CompensationGraph from './CompensationGraph';
import type { Role } from './CompensationGraph';

const useStyles = makeStyles(theme => ({
  filters: {
    marginBottom: theme.spacing(2)
  },
  graphContainer: {
    height: 'calc(100% - 120px)'
  }
}));

type Props = {
  role: Role
} & ModalProps;

const CompensationModal = ({ isOpen, onClose, role }: Props): React$Node => {
  const classes = useStyles();
  const [companySize, setCompanySize] = useState('all');
  const [industry, setIndustry] = useState('all');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography gutterBottom variant='h3'>{role} Compensation</Typography>
      <Grid className={classes.filters} container spacing={2}>
        <Grid item xs={6} md={4}>
          <CompanySizeSelect value={companySize} onChange={e => setCompanySize(e.target.value)} />
        </Grid>
        <Grid item xs={6} md={4}>
          <IndustrySelect value={industry} onChange={e => setIndustry(e.target.value)} />
        </Grid>
      </Grid>
      <div className={classes.graphContainer}>
        <CompensationGraph
          enableLabel
          role={role}
          industry={industry}
          companySize={companySize}
        />
      </div>
    </Modal>
  );
};

export default CompensationModal;
