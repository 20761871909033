// @flow
import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';

import { palette, fontSize, fontWeight } from 'constants/theme';

const theme: Object = createMuiTheme({
  palette: {
    primary: {
      main: palette.blue
    },
    secondary: {
      main: '#a6bfcd'
    },
    text: {
      primary: palette.black
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    htmlFontSize: 16,
    button: {
      fontWeight: fontWeight.bold,
      textTransform: 'none'
    },
    h1: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.h1
    },
    h2: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.h2
    },
    h3: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.h3,
    },
    h4: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.h4
    },
    h5: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.h5
    },
    h6: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.h6
    },
    subtitle1: {
      fontWeight: fontWeight.medium,
    },
    subtitle2: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.small
    },
    body1: {
      fontSize: fontSize.default
    },
    body2: {
      fontSize: fontSize.small
    },
    caption: {
      fontWeight: fontWeight.bold,
      fontSize: fontSize.xsmall
    }
  },
  props: {
    MuiButton: {
      variant: 'contained',
      disableElevation: true
    },
    MuiTextField: {
      variant: 'outlined'
    },
    MuiFormControl: {
      variant: 'outlined'
    },
    MuiSelect: {
      variant: 'outlined'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Montserrat',
          width: '100%',
          height: '100%',
          overflow: 'hidden'
        },
        p: {
          marginTop: 0
        },
        'p:last-child': {
          marginBottom: 0
        }
      },
    },
    MuiStepLabel: {
      label: {
        '&$active': {
          fontWeight: fontWeight.bold
        }
      }
    },
    MuiLink: {
      root: {
        color: palette.blue,
        cursor: 'pointer'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: fontSize.medium
      }
    },
    MuiTextField: {
      root: {
        fontSize: fontSize.medium,
        '&$focused': {

        }
      }
    },
    MuiInputBase: {
      root: {
        fontSize: fontSize.medium
      },
      input: {
        fontSize: fontSize.medium
      }
    },
    MuiButton: {
      root: {
        fontSize: fontSize.medium,
        padding: '11px 22px'
      },
      contained: {
        backgroundColor: palette.blue,
        color: palette.white,
        '&:hover': {
          backgroundColor: palette.blue,
          filter: 'brightness(0.9)'
        }
      },
      sizeLarge: {
        fontSize: fontSize.default,
        padding: '11px 22px'
      }
    }
  }
});

export default theme;
