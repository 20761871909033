// @flow
import request from './ApiClient';
import { snakeCaseKeys } from './DataFormatter';
import type { SerializedApiObject } from 'types/ApiTypes';
import type { User } from 'types/UserTypes';

const fetch = (id: number): Promise<SerializedApiObject<User>> => {
  return request({
    url: `/users/${id}`,
    method: 'GET'
  });
};

const update = (id: number, data: $Shape<User>): Promise<SerializedApiObject<User>> => {
  return request({
    url: `/users/${id}`,
    method: 'PATCH',
    data: snakeCaseKeys(data)
  });
};

const updatePreferences = (id: number, type: string, data: {}): Promise<SerializedApiObject<User>> => {
  return request({
    url: `/users/${id}/preferences/${type}`,
    method: 'PATCH',
    data: {
      data: data
    }
  });
};

const UserService = {
  fetch,
  update,
  updatePreferences
};

export default UserService;
