// @flow
import React from 'react';

import { industries } from 'constants/selectOptions';
import SimpleSelect from './SimpleSelect';

import type { SimpleSelectProps } from './SimpleSelect';

const IndustrySelect = (props: SimpleSelectProps): React$Node => {
  return (
    <SimpleSelect
      {...props}
      id={props.id || 'industry'}
      name={props.name || 'industry'}
      label={props.label || 'Industry'}
      options={industries}
    />
  );
};

export default IndustrySelect;
