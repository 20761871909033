// @flow
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { StateSelect } from 'components/Select';

type DefaultData = *

type Props = {
  defaultData: ?DefaultData,
  onUpdate: DefaultData => void,
  errors: ?{
    name?: string,
    city?: string,
    state?: string,
    jobTitle?: string,
    companyName?: string
  }
};

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(4)
  }
}));

const OnboardingBasics = ({ defaultData, onUpdate, errors }: Props): React$Node => {
  const [data, setData] = useState(defaultData || {
    name: '',
    city: '',
    state: '',
    jobTitle: '',
    companyName: ''
  })

  const updateForm = e => {
    setData(state => ({
      ...state,
      [e.target.name]: e.target.value
    }))
  }

  // Pass updates to data back up to the parent component
  useEffect(() => {
    onUpdate(data)
  }, [data])

  return (
    <div>
      <Container>
        <form>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={10}>
              <Typography variant="h3">The Basics</Typography>
              <Typography>Please take a moment to complete or verify your information</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                value={data.name}
                label='Full Name'
                error={!!errors?.name}
                helperText={errors?.name}
                required
                name='name'
                onChange={e => updateForm(e)}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                value={data.city}
                label='City'
                error={!!errors?.city}
                helperText={errors?.city}
                required
                name='city'
                onChange={e => updateForm(e)}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <StateSelect
                value={data.state}
                onChange={updateForm}
                error={!!errors?.state}
                helperText={errors?.state}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                value={data.jobTitle}
                label='Current Job Title'
                error={!!errors?.jobTitle}
                helperText={errors?.jobTitle}
                required
                name='jobTitle'
                onChange={e => updateForm(e)}
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                value={data.companyName}
                label='Current Company'
                error={!!errors?.companyName}
                helperText={errors?.companyName}
                required
                name='companyName'
                onChange={e => updateForm(e)}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  )
}

export default OnboardingBasics
