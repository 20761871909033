// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import WelcomeCarouselImage0 from 'assets/pngs/welcomeCarousel0.png';
import WelcomeCarouselImage1 from 'assets/pngs/welcomeCarousel1.png';
import WelcomeCarouselImage2 from 'assets/pngs/welcomeCarousel2.png';
import WelcomeCarouselImage3 from 'assets/pngs/welcomeCarousel3.png';
import WelcomeCarouselImage4 from 'assets/pngs/welcomeCarousel4.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const steps = [
  {
    header: 'Understand your own leadership profile through proprietary assessment tools.',
    imageSrc: WelcomeCarouselImage0
  },
  {
    header: `Work with Heidrick's top-tier leadership coaches to make sure you're set up for success.`,
    imageSrc: WelcomeCarouselImage1
  },
  {
    header: 'Engage with the highest caliber of peers and mentors on topics you care about most.',
    imageSrc: WelcomeCarouselImage2
  },
  {
    header: `Discover the skills you need to reach your career goals.`,
    imageSrc: WelcomeCarouselImage3
  },
  {
    header: 'Understand your current compensation and benchmark it against the field.',
    imageSrc: WelcomeCarouselImage4
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  swipeableContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '400px'
  },
  img: {
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
    margin: theme.spacing(0, 'auto', 1)
  },
  header: {
    marginBottom: theme.spacing(6),
    maxWidth: '500px'
  },
  stepper: {
    display: 'flex',
    width: '160px',
    justifyContent: 'space-between',
    margin: '0 auto'
  },
  step: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: '#d8d8d8',
    opacity: 0.25,
    cursor: 'pointer'
  },
  activeStep: {
    opacity: 1
  }
}));

const WelcomeCarousel = (): React$Node => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Container className={classes.root}>
      <div className={classes.swipeableContainer}>
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={6000}
        >
          {steps.map((step, index) => (
            <div key={step.header}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img className={classes.img} src={step.imageSrc} alt={step.header} />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <div>
          <Typography variant='h5' align='center' className={classes.header}>{steps[activeStep].header}</Typography>
          <div className={classes.stepper}>
            {steps.map((step, index) => {
              let className = classes.step;
              if (index === activeStep) {
                className = `${classes.step} ${classes.activeStep}`
              }

              return (
                <div className={className} onClick={() => handleStepChange(index) }/>
              )
            })}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default WelcomeCarousel;
