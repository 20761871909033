// @flow
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import { withCardStyle } from 'services/decorators';
import CalendarBlue from 'assets/svgs/calendarBlue.svg';
import Lockable from 'components/Lockable';
import BookCoachingSessionButton from 'components/BookCoachingSessionButton';
import { useSessionUser } from 'hooks';

import { palette, fontSize } from 'constants/theme';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  eventGrid: {
    marginTop: theme.spacing(2)
  },
  card: {
    width: '100%'
  },
  actionCard: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  cardActions: {
    width: '100%'
  },
  calendarImage: {
    margin: theme.spacing(0, 'auto', 1),
    display: 'block'
  },
  avatar: {
    background: 'none',
    color: palette.blue,
    fontSize: fontSize.h1
  }
}));

const DashboardCard = withCardStyle(Card);

const Assessments = (): React$Node => {
  const { alpUrl, alpReportUrl, laqUrl, laqReportUrl, trackedActions } = useSessionUser();
  const classes = useStyles();
  const locked = !trackedActions.completedAssessment;
  const laqText = trackedActions.completedLaq ? 'View LAQ Report' : 'Take the LAQ';
  const laqUrlDisabled = !laqReportUrl && !laqUrl;
  const alpText = trackedActions.completedAlp ? 'View ALP Report' : 'Take the ALP';
  const alpUrlDisabled = !alpReportUrl && !alpUrl;
  return (
    <Grid container spacing={2}>
      <Grid item lg={4} sm={6} xs={12}>
        <DashboardCard className={classes.actionCard}>
          <CardContent>
            <Typography variant='subtitle2' align='center' gutterBottom>Complete the Leadership Accelerator Questionnaire (LAQ) assessment</Typography>
            <Typography variant='body2' align='center'>
              <a style={{ textDecoration: 'none', color: palette.blue }} href="https://www.heidrick.com/What-We-Do/Heidrick-Consulting/Service/Accelerating-Leaders" target="_blank">
                Click Here To Learn More
              </a>
            </Typography>
          </CardContent>
          <Tooltip title={!laqUrlDisabled ? '' : 'Your LAQ assessment is not quite ready!'}>
            <CardActions className={classes.cardActions}>
              <Button
                fullWidth
                href={laqReportUrl || laqUrl}
                target={'_blank'}
                disabled={laqUrlDisabled}
              >
                {laqText}
              </Button>
            </CardActions>
          </Tooltip>
        </DashboardCard>
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <DashboardCard className={classes.actionCard}>
          <CardContent>
            <Typography variant='subtitle2' align='center' gutterBottom>Complete the Agile Leader Potential (ALP) assessment</Typography>
            <Typography variant='body2' align='center'>
              <a style={{ textDecoration: 'none', color: palette.blue }} href="https://www.heidrick.com/What-We-Do/Heidrick-Consulting/Service/Agile-Leader-Potential-(ALP)" target="_blank">
                Click Here To Learn More
              </a>
            </Typography>
          </CardContent>
          <Tooltip title={!alpUrlDisabled ? '' : 'Your ALP assessment is not quite ready!'}>
            <CardActions className={classes.cardActions}>
              <Button
                fullWidth
                href={alpReportUrl || alpUrl}
                target={'_blank'}
                disabled={alpUrlDisabled}
              >
                {alpText}
              </Button>
            </CardActions>
          </Tooltip>
        </DashboardCard>
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <Lockable
          locked={locked}
          text={'Complete an assessment to unlock this feature'}
          Container={DashboardCard}
          ContainerProps={{ className: classes.actionCard }}
        >
          <CardContent>
            <img src={CalendarBlue} className={classes.calendarImage} />
            <Typography variant='subtitle2' align='center' gutterBottom>Book a Session with a Coach</Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <BookCoachingSessionButton
              fullWidth
              disabled={locked}
            >
              {trackedActions.bookedCoachingSession ? 'Book Another Coaching Session' : 'Book Coaching Session'}
            </BookCoachingSessionButton>
          </CardActions>
        </Lockable>
      </Grid>
    </Grid>
  );
};

export default Assessments;
