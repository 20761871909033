// @flow
import axios from 'axios';
import pick from 'lodash/pick';

import type { NormalizedApiResponse } from 'types/ApiTypes';

const env = process.env.NODE_ENV;

const csrfMetaTag = global.document.getElementsByName('csrf-token')[0];

const client: * = axios.create({
  baseURL: `${window.location.origin}/api`,
  headers: {
    Accept: 'application/vnd.hs-odyssey.v1'
  }
});

const request = (options: {}): Promise<*> => {
  const onSuccess = response => {

    // store returned authentication headers
    if (response.headers['access-token']) {
      const authHeaders = pick(response.headers, ['access-token', 'client', 'expiry', 'uid', 'token-type']);
      localStorage.setItem('authHeaders', JSON.stringify(authHeaders));
    }

    return response.data;
  };

  const onError = error => {
    if (env === 'test') {
      console.error('Network Request:', error.config.url);
      return;
    }

    return Promise.reject(error.response || error.message);
  };

  // Add the csrf meta tag if it exists
  if (csrfMetaTag) {
    client.defaults.headers.common['X-CSRF-Token'] = csrfMetaTag.content
  }

  // Add authentication headers
  const storedHeaders = localStorage.getItem('authHeaders')
  const authHeaders = storedHeaders && JSON.parse(storedHeaders);
  if (authHeaders) {
    client.defaults.headers.common['access-token'] = authHeaders['access-token']
    client.defaults.headers.common['client'] = authHeaders['client']
    client.defaults.headers.common['expiry'] = authHeaders['expiry']
    client.defaults.headers.common['uid'] = authHeaders['uid']
    client.defaults.headers.common['token-type'] = authHeaders['token-type']
  }

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export { request as default, client };