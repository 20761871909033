// @flow
import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import AuthenticationPage from 'components/AuthenticationPage';
import ForgotPasswordLink from 'components/ForgotPasswordLink';
import * as ROUTES from 'constants/routes';

import { PasswordService } from 'services/api';

const ResetPassword = (): React$Node => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [errors, setErrors] = useState(undefined);
  const location = useLocation();
  const history = useHistory();

  const onSubmit = () => {
    const data = queryString.parse(location.search);
    data.password = password;
    data.password_confirmation = passwordConfirmation;

    PasswordService.update(data)
      .then(response => setResetSuccessful(true))
      .catch(response => {
        const possibleErrors = response.data.errors
        const newErrors = {};

        Object.keys(possibleErrors).forEach(errorKey => {
          const error = possibleErrors[errorKey];
          if (error) {
            const message = typeof error === 'string' ? `${errorKey.split('_').map(part => capitalize(part)).join(' ')} ${error[0]}` : error.message;
            newErrors[errorKey] = message;
          }
        });

        setErrors(newErrors);
      });
  };

  const updateForm = (e, callback) => {
    // setErrors(undefined);
    callback(e.target.value);
  };

  const SuccessMessage = () => (
    <>
      <Grid item xs={12}>
        <Typography>Your password has been successfully reset. You can now sign in with your updated credentials.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          size='large'
          onClick={() => history.push(ROUTES.SIGN_IN)}
        >
          Sign In
        </Button>
      </Grid>
    </>
  );

  return (
    <AuthenticationPage onSubmit={onSubmit}>
      {resetSuccessful ? <SuccessMessage /> : (
        <>
          <Grid item xs={12}>
            <TextField
              value={password}
              type='password'
              label='New Password'
              required
              error={!!errors?.password}
              helperText={errors?.password}
              onChange={e => updateForm(e, setPassword)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={passwordConfirmation}
              type='password'
              label='New Password Confirmation'
              required
              error={!!errors?.password_confirmation}
              helperText={errors?.password_confirmation}
              onChange={e => updateForm(e, setPasswordConfirmation)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              size='large'
              type='submit'
            >
              Reset Password
            </Button>
          </Grid>
          {errors?.unauthorized && (
            <Grid item xs={12}>
              <Typography variant='subtitle1' color='error'>{errors.unauthorized}</Typography>
              <Typography variant='subtitle1' color='textSecondary'><ForgotPasswordLink /></Typography>
            </Grid>
          )}
        </>
      )}
    </AuthenticationPage>
  );
};

export default ResetPassword;
