// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';

import App from 'components/App';
import { SessionProvider } from 'components/Session';
import { ThemeProvider } from 'components/Theme';
import { NotificationProvider } from 'components/Notification';

document.addEventListener('DOMContentLoaded', () => {
  if (document.body) {
    ReactDOM.render(
      <Router>
        <ThemeProvider>
          <CssBaseline />
          <SessionProvider>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </SessionProvider>
        </ThemeProvider>
      </Router>,
      document.body.appendChild(document.createElement('div')),
    )
  }
});
