// @flow
import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';

import { palette } from 'constants/theme';

const useStyles = makeStyles(theme => ({
  lockScreenText: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: props => props.locked ? 'flex' : 'none',
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 2,
    color: palette.white
  },
  lockScreenOverlay: {
    position: 'absolute',
    display: props => props.locked ? 'block' : 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: palette.blue,
    opacity: 0.8,
  }
}));

type Props = {
  children: React$Node,
  locked: boolean,
  text?: string,
  Container: React.AbstractComponent<{}>,
  ContainerProps: *
};

const Lockable = ({ children, locked, text, Container, ContainerProps }: Props): React$Node => {
  const classes = useStyles({ locked });

  return (
    <Container {...ContainerProps} style={{ position: 'relative' }}>
      <div className={classes.lockScreenOverlay} />
      <div className={classes.lockScreenText}>
        <LockIcon fontSize='large' color='inherit' />
        <Typography variant='h5' color='inherit' align='center' style={{ marginTop: '16px' }}>{text}</Typography>
      </div>
      {children}
    </Container>
  )
};

export default Lockable;
