// @flow
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { ResponsiveBar } from '@nivo/bar';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import reduce from 'lodash/reduce';
import toInteger from 'lodash/toInteger';
import orderBy from 'lodash/orderBy';
import max from 'lodash/max';
import replace from 'lodash/replace';

import { palette } from 'constants/theme';
import compensationData from 'assets/data/CompensationITOP.json';
import { companySizes, industries } from 'constants/selectOptions';

export type Role = 'CIO' | 'CEO';

type Props = {
  role: Role,
  industry?: string,
  companySize?: string,
  enableLabel?: boolean,
  isInteractive?: boolean
};

const allData = {
  CIO: compensationData,
  CEO: []
};

const CompensationGraph = ({
  role,
  industry = 'all',
  companySize = 'all',
  enableLabel = false,
  isInteractive = false
}: Props): React$Node => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const roleData = allData[role];
    const labels = ['Director-Level', 'VP/Partner-Level', 'President/C-Level'];
    const companySizeKey = companySizes.find(size => size.id === companySize)?.dataValue;
    const industryKey = industries.find(ind => ind.id === industry)?.dataValue;

    setData(labels.map(label => {
      const filteredData = roleData.filter(comp => {
        if (comp['level'] !== label) return false;
        if (industryKey && comp.industry !== industryKey) return false;
        if (companySizeKey && comp.company_size !== companySizeKey) return false;

        return true;
      });
      const count = max([filteredData.length, 1]);
      const newData = reduce(filteredData, (result, comp) => {
        result.base = (result.base || 0) + toInteger(comp.base_salary);
        result.bonus = (result.bonus || 0) + toInteger(comp.incentive_bonus);

        return result;
      }, {});

      return {
        level: label,
        'Base': (newData.base || 0) / count,
        'Bonus': (newData.bonus || 0) / count
      }
    }));
  }, [industry, companySize]);

  const renderLabel = ({ id, value, data }) => {
    let idLabel = id;
    let valueToFormat = value;
    if (valueToFormat === 0) return null;

    if (id === 'Bonus') {
      idLabel = 'Base + Bonus';
      valueToFormat = data['Bonus'] + data['Base'];
    }

    const intValue = toInteger(valueToFormat)
    const displayValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(intValue);

    return `${idLabel}: ${replace(displayValue, '.00', '')}`;
  };

  return (
    <ResponsiveBar
      data={data}
      theme={{ fontFamily: 'Montserrat' }}
      keys={['Base', 'Bonus']}
      indexBy={'level'}
      margin={{ top: 0, right: 0, bottom: 24 }}
      valueScale={{ type: 'linear' }}
      colors={[palette.blue, '#a6bfcd']}
      animate={true}
      enableGridY={false}
      isInteractive={isInteractive}
      tooltip={renderLabel}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
      }}
      enableLabel={enableLabel}
      label={renderLabel}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 60,
          itemHeight: 20,
          symbolSize: 20,
        }
      ]}
    />
  );
};

export default CompensationGraph;
