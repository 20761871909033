// @flow
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import SlackChannelList from 'components/SlackChannelList';
import SlackChannelListModal from 'components/SlackChannelListModal';
import { SlackChannelService } from 'services/api';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    display: 'block'
  }
}));

const limit = 1;

const SlackChannelPreviewList = (): React$Node => {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [slackChannelModalOpen, setSlackChannelModalOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    SlackChannelService.list({ limit })
      .then(response => {
        setChannels(response.data.map(d => d.attributes));
        setLoading(false);
      })
  }, []);

  if (loading || channels.length === 0) return null;

  return (
    <>
      <SlackChannelList channels={channels} />
    </>
  );
};

export default SlackChannelPreviewList;
