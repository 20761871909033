// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import FullPageBox from 'components/FullPageBox';
import PolicyLinks from 'components/PolicyLinks';
import LogoDark from 'assets/svgs/logoDark.svg';
import LoginBackground from 'assets/svgs/loginBackground.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    background: `url(${LoginBackground}) no-repeat center center`,
    backgroundSize: 'cover'
  },
  paperRoot: {
    padding: theme.spacing(4)
  },
  logo: {
    width: '200px',
    margin: theme.spacing(4, 0)
  },
  links: {
    width: '100%',
    color: 'white',
    marginTop: theme.spacing(6)
  }
}));

const AuthenticationPage = ({ onSubmit, children  }: { onSubmit: () => void, children: React$Node }): React$Node => {
  const classes = useStyles();

  const handleSubmit = e => {
    e.preventDefault();

    onSubmit();
  };

  return (
    <FullPageBox className={classes.root}>
      <Container maxWidth='sm'>
        <form onSubmit={handleSubmit}>
          <Paper elevation={2} className={classes.paperRoot}>
            <Grid container spacing={3}>
              <Grid container item xs={12} justify='center'>
                <Link to='/'>
                  <img src={LogoDark} alt="Odyssey Logo" className={classes.logo} />
                </Link>
              </Grid>
              {children}
            </Grid>
          </Paper>
        </form>
      </Container>
      <Typography align='center' variant={'subtitle1'} className={classes.links}>
        <PolicyLinks connector={' • '} withContact />
      </Typography>
    </FullPageBox>
  );
};

export default AuthenticationPage;
