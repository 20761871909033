// @flow
export const CONTACT = '/contact'
export const DASHBOARD = '/';
export const ONBOARDING = '/welcome'
export const PROFILE = '/profile'
export const RESOURCE_CENTER = '/resource-center'
export const SIGN_IN = '/sign-in'
export const SIGN_UP = '/sign-up'
export const TERMS_AND_CONDITIONS = '/terms-and-conditions'
export const PRIVACY_POLICY = '/privacy-policy'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
