// @flow
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { withCardStyle } from 'services/decorators';

import { palette } from 'constants/theme';

type UserData = *;

type Props = {
  user: ?UserData
};

const DashboardCard = withCardStyle(Card);

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(4)
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  actionCard: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  cardActions: {
    width: '100%'
  }
}))

const Review = ({ user }: Props): React$Node => {
  const classes = useStyles()
  const [error, setError] = useState(undefined)

  if (!user) return null
  const laqUrl = user.laqUrl
  const alpUrl = user.alpUrl

  return (
    <div>
      <Container>
        <form>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={10}>
              <Typography variant="h3">Prep for your coaching session</Typography>
              <Typography>Please take one or both of the following assessments prior to scheduling your first coaching session.</Typography>
            </Grid>
            <Grid container spacing={2} justify='center'>
              <Grid item xs={10}>
                <DashboardCard className={classes.actionCard}>
                  <CardContent>
                    <Typography variant='subtitle2' align='center' gutterBottom>Complete the Leadership Accelerator Questionaire (LAQ) assessment</Typography>
                    <Typography variant='body2' align='center'>
                      <a style={{ textDecoration: 'none', color: palette.blue }} href="https://www.heidrick.com/What-We-Do/Heidrick-Consulting/Service/Accelerating-Leaders" target="_blank">
                        Click Here To Learn More
                      </a>
                    </Typography>
                  </CardContent>
                  <Tooltip title={laqUrl ? '' : 'Your LAQ assessment is not quite ready!'}>
                    <CardActions className={classes.cardActions}>
                      <Button
                        fullWidth
                        href={laqUrl}
                        target={'_blank'}
                        disabled={!laqUrl}
                      >
                        Take the LAQ
                      </Button>
                    </CardActions>
                  </Tooltip>
                </DashboardCard>
              </Grid>
              <Grid item xs={10}>
                <DashboardCard className={classes.actionCard}>
                  <CardContent>
                    <Typography variant='subtitle2' align='center' gutterBottom>Complete the Agile Leader Potential (ALP) assessment</Typography>
                    <Typography variant='body2' align='center'>
                      <a style={{ textDecoration: 'none', color: palette.blue }} href="https://www.heidrick.com/What-We-Do/Heidrick-Consulting/Service/Agile-Leader-Potential-(ALP)" target="_blank">
                        Click Here To Learn More
                      </a>
                    </Typography>
                  </CardContent>
                  <Tooltip title={alpUrl ? '' : 'Your ALP assessment is not quite ready!'}>
                    <CardActions className={classes.cardActions}>
                      <Button
                        fullWidth
                        href={alpUrl}
                        target={'_blank'}
                        disabled={!alpUrl}
                      >
                        Take the ALP
                      </Button>
                    </CardActions>
                  </Tooltip>
                </DashboardCard>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  )
}

export default Review
