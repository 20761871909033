// @flow
import React, { useContext } from 'react';

import { SessionContext } from 'components/Session';

import type { SessionUser, TrackedActions } from 'types/UserTypes';

type UseSessionUserType = SessionUser & {
  update: $Shape<SessionUser> => void,
  updateTrackedActions: $Shape<TrackedActions> => void
};

const useSessionUser = (): UseSessionUserType => {
  const { sessionUser, setSessionUser } = useContext(SessionContext);

  const update = (updates: $Shape<SessionUser>) => {
    setSessionUser(prev => ({ ...prev, ...updates }));
  };

  const updateTrackedActions = (updates: $Shape<TrackedActions>) => {
    update({ trackedActions: { ...sessionUser.trackedActions, ...updates } });
  };

  return { ...sessionUser, update, updateTrackedActions };
};

export default useSessionUser;
