// @flow
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import Chip from '@material-ui/core/Chip';

import FullPageBox from 'components/FullPageBox';

import { withCardStyle } from 'services/decorators';
import { ResourceArticleService } from 'services/api';

const ResourceCard = withCardStyle(Card);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  cardStyle: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  description: {
    marginBottom: theme.spacing(2)
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    },
    paddingTop: theme.spacing(1)
  }
}));

const Resources = (): React$Node => {
  const classes = useStyles();
  const [articles, setArticles] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    ResourceArticleService.list()
      .then(response => setArticles(response.data.map(d => d.attributes)))
  }, []);

  const onTagClick = tag => {
    const tagText = tag.target.innerText;
    if (!filters.includes(tagText)) {
      setFilters([...filters, tagText])
    }
  };

  const onTagDelete = tagText => setFilters(filters.filter(tag => tag !== tagText));

  const articleFilterFunc = (article) => {
    if (!filters.length) return true;

    return article.tagList.some(tag => filters.includes(tag));
  }

  return (
    <FullPageBox withNav overflow='scroll'>
      <Container className={classes.root}>
        <Typography variant="h1" gutterBottom>
          Resource Center
        </Typography>
        {filters.length ?
          <div className={classes.tags}>
            {filters.map(filter => <Chip onDelete={() => onTagDelete(filter)} size="small" label={filter} key={filter} />)}
          </div>
        : null}
        {articles.filter(articleFilterFunc).map(article => (
          <ResourceCard className={classes.cardStyle} key={article.id}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <div className={classes.imageContainer}>
                  <img src={article.imageUrl} className={classes.image} />
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h6" className={classes.title}>{article.title}</Typography>
                <Typography variant="body2" className={classes.description}>{article.description}</Typography>
                <Link href={article.url} target={'_blank'} style={{ textDecoration: 'none' }}>
                  <Button>
                    <Typography variant="h6">
                      {article.articleType === 'Podcast' ? 'Listen to Podcast' : 'Read Full Article'}
                    </Typography>
                  </Button>
                </Link>
                <div className={classes.tags}>
                  {article.tagList.map(tag => <Chip onClick={onTagClick} size="small" label={tag} key={`${article.id}-${tag}`} />)}
                </div>
              </Grid>
            </Grid>
          </ResourceCard>
        ))}
      </Container>
    </FullPageBox>
  );
};

export default Resources;
