// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Modal from 'components/Modal';
import { fontSize, fontWeight, palette } from 'constants/theme';

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(3)
  },
  border: {
    border: 'solid 1px rgba(0, 6, 46, 0.25)'
  },
  infoSection: {
    display: 'flex'
  },
  infoIcon: {
    borderRight: 'solid 1px rgba(0, 6, 46, 0.25)',
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    fontWeight: fontWeight.medium,
    fontSize: fontSize.medium,
    display: 'block'
  },
  navLabel: {
    fontSize: fontSize.medium,
    marginBottom: theme.spacing(3),
    cursor: 'pointer'
  },
  title: {
    backgroundColor: palette.black,
    color: palette.white,
    padding: theme.spacing(1, 6),
    width: 'fit-content'
  },
  navSection: {
    backgroundColor: 'rgba(0, 6, 46, 0.05)',
    padding: theme.spacing(4, 3)
  },
  sectionContent: {
    padding: theme.spacing(2, 3)
  }
}));

const SuccessProfileModal = ({ profile, isOpen, onClose }: { profile: *, isOpen: boolean, onClose: () => void }): React$Node => {
  const classes = useStyles();
  const [activeSection, setActiveSection] = useState(profile.sections[0]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={`${classes.infoSection} ${classes.border}`}>
            <div className={`${classes.padding} ${classes.infoIcon}`}>
              <InfoIcon />
            </div>
            <div className={classes.padding}>
              <Typography className={classes.content}>
                {`Heidrick’s executive search experts have helped the most successful organizations build their senior leadership teams. Through assessing thousands of top-tier candidates, Heidrick has identified the key competencies of world class executives.`}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.title}>
            <Typography variant='h6'>{profile.title}</Typography>
          </div>
          <div className={`${classes.border} ${classes.padding}`}>
            <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: profile.description }} />
          </div>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={12} md={3} alignItems='center' alignContent='flex-start' className={classes.navSection}>
            {profile.sections.map(section => {
              const label = section.label;
              const style = label === activeSection.label ? { fontWeight: fontWeight.bold } : {}

              return (
                <Grid key={label} item xs md={12}>
                  <Link color='inherit' onClick={() => setActiveSection(section)}>
                    <Typography className={classes.navLabel} style={style} component='div'>{section.label}</Typography>
                  </Link>
                </Grid>
              )
            })}
          </Grid>
          <Grid item xs={12} md={9} className={classes.sectionContent}>
            <Typography component='div' className={classes.content} dangerouslySetInnerHTML={{ __html: activeSection.content }} />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
};

export default SuccessProfileModal;
