// @flow
import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { RegistrationService } from 'services/api';
import { SessionContext } from 'components/Session';
import AuthenticationPage from 'components/AuthenticationPage';
import * as ROUTES from 'constants/routes';

const SignUp = (): React$Node => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [checked, setChecked] = useState(false);
  const { signUp, errors, setErrors } = useContext(SessionContext);
  const { invitationToken } = useParams();

  useEffect(() => {
    if (!invitationToken) return;

    RegistrationService.fetchUserByInvitation(invitationToken)
      .then(response => {
        setEmail(response.data.attributes.email);
      })
      .catch(response => {
        setErrors(response.data.errors)
      });
  }, []);

  const onSubmit = () => {
    if (!checked) {
      setErrors({ terms: 'You must accept the terms and privacy policies to create an account.' });
    } else {
      signUp(password, passwordConfirmation, invitationToken);
    }
  };

  const updateForm = (e, callback) => {
    setErrors(undefined);
    callback(e.target.value);
  };

  const handleChange = e => {
    setErrors(undefined);
    setChecked(e.target.checked);
  }

  return (
    <AuthenticationPage onSubmit={onSubmit}>
      <Grid item xs={12}>
        <TextField
          value={email}
          disabled
          label='Email'
          error={!!errors?.email}
          helperText={errors?.email}
          onChange={e => updateForm(e, setEmail)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={password}
          type='password'
          label='Password'
          required
          error={!!errors?.password}
          helperText={errors?.password}
          onChange={e => updateForm(e, setPassword)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={passwordConfirmation}
          type='password'
          label='Password Confirmation'
          required
          error={!!errors?.password_confirmation}
          helperText={errors?.password_confirmation}
          onChange={e => updateForm(e, setPasswordConfirmation)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl errors={!!errors?.terms}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                name="checked"
                required
              />
            }
            label={
              <>
                {'I accept the '}
                <a href={ROUTES.TERMS_AND_CONDITIONS} target='_blank'>Terms & Conditions</a>
                {' and '}
                <a href={ROUTES.PRIVACY_POLICY} target='_blank'>Privacy Policy</a>
              </>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          size='large'
          type='submit'
        >
          Create Password and Login
        </Button>
      </Grid>
      <Typography align='center' style={{ marginTop: 20, width: '100%' }}>
        Already have an account? <Link to='/'>Sign In</Link>
      </Typography>
    </AuthenticationPage>
  );
};

export default SignUp;
