// @flow
import request from './ApiClient';
import type { SerializedApiCollection } from 'types/ApiTypes';
import type { ResourceArticle } from 'types/ResourceArticleTypes';

const list = (): Promise<SerializedApiCollection<ResourceArticle>> => {
  return request({
    url: '/resource_articles',
    method: 'GET'
  });
};

const ResourceArticleService = {
  list
};

export default ResourceArticleService;
