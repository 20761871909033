// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  cardRoot: {
    height: '100%'
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 500
  }
})

const Confirmation = (): React$Node => {
  const classes = useStyles()

  return (
    <div>
      <Container>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">You're all set!</Typography>
              <Typography>Visit your custom dashboard to connect through:</Typography>
            </Grid>
            <Grid item container xs={12} spacing={2} alignContent="stretch" justify="center">
              <Grid item sm={12} md={6} lg={4}>
                <Card className={classes.cardRoot}>
                  <CardContent>
                    <Typography className={classes.cardTitle}>
                      Peer Networking
                    </Typography>
                    <Typography variant="body2" component="p">
                      Connect with peers in your cohort who understand the challenges you're facing and can provide a valuable perspective.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Card className={classes.cardRoot}>
                  <CardContent>
                    <Typography className={classes.cardTitle}>
                      Personalized Mentorship
                    </Typography>
                    <Typography variant="body2" component="p">
                      Request mentoring sessions from best-in-class leaders who have been in your shoes and will show you the way.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Card className={classes.cardRoot}>
                  <CardContent>
                    <Typography className={classes.cardTitle}>
                      Premier Coaching
                    </Typography>
                    <Typography variant="body2" component="p">
                      Maximize your experience with top-tier executive coaches by leveraging Heidrick's proprietary leadership assessment tools.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Link to='/' style={{ textDecoration: 'none' }}>
                <Button>Go To My Dashboard</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  )
}

export default Confirmation
