// @flow
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import { SessionService, TokenValidationService, RegistrationService } from 'services/api';

import * as ROUTES from 'constants/routes';

import type { SessionUser } from 'types/UserTypes';

type Errors = {
  [string]: string
};

const SessionContext: Object = React.createContext<{
  loading: boolean,
  sessionUser: ?SessionUser,
  setSessionUser: ?SessionUser => void,
  signIn: (email: string, password: string) => void,
  signUp: (email: string, password: string, passwordConfirmation: string) => void,
  signOut: () => void,
  errors: ?Errors,
  setError: string => void
}>({
  loading: true,
  sessionUser: null,
  setSessionUser: () => {},
  signIn: (email, password) => {},
  signUp: (email, password, passwordConfirmation) => {},
  signOut: () => {},
  errors: undefined,
  setError: string => {}
});

export default SessionContext;

export const SessionProvider = ({ children }: { children: React$Node }): React$Node => {
  const [loading, setLoading] = useState(true);
  const [sessionUser, setSessionUser] = useState(null);
  const [errors, setErrors] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    TokenValidationService.validateToken()
      .then(response => handleSessionStart(response.data.attributes))
      .catch(() => handleSessionEnd());
  }, []);

  const signIn = (email: string, password: string) => {
    SessionService.create({ email, password })
      .then(response => {
        handleSessionStart(response.data.attributes);
        history && history.push(ROUTES.DASHBOARD);
      })
      .catch(response => handleSessionEnd({ credentials: response.data.errors[0] }));
  };

  const signUp = (password: string, password_confirmation: string, token: string) => {
    RegistrationService.update({ password, password_confirmation, token })
      .then(response => {
        setLoading(true);
        history && history.push(ROUTES.ONBOARDING);
        handleSessionStart(response.data.attributes);        
      })
      .catch(response => {
        const possibleErrors = response.data.errors
        const newErrors = {};

        Object.keys(possibleErrors).forEach(errorKey => {
          const error = possibleErrors[errorKey];
          if (error) {
            newErrors[errorKey] = `${errorKey.split('_').map(part => capitalize(part)).join(' ')} ${error[0]}`;
          }
        });

        handleSessionEnd(newErrors)
      });
  };

  const signOut = () => {
    SessionService.destroy()
      .then(() => {
        handleSessionEnd();
        history && history.push(ROUTES.SIGN_IN);
      });
  };

  const handleSessionStart = user => {
    setErrors(undefined);
    setSessionUser(user);
    setLoading(false);
  };

  const handleSessionEnd = (newErrors?: Errors) => {
    if (newErrors) setErrors(newErrors);
    localStorage.removeItem('authHeaders');
    setSessionUser(null);
    setLoading(false)
  };

  const value = {
    loading,
    sessionUser,
    setSessionUser,
    signIn,
    signUp,
    signOut,
    errors,
    setErrors
  }

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  )
};
