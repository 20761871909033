import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Dashboard from 'pages/Dashboard'
import Onboarding from 'pages/Onboarding'
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import ResourceCenter from "pages/ResourceCenter";
import TermsAndConditions from 'pages/TermsAndConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Profile from 'pages/Profile';
import Contact from 'pages/Contact';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';

import { SessionContext } from 'components/Session'

import * as ROUTES from 'constants/routes';

const AuthenticatedApp = (props) => {
  return (
    <Switch>
      <Route path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
      <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
      <Route path={ROUTES.ONBOARDING} component={Onboarding} />
      <Route path={ROUTES.RESOURCE_CENTER} component={ResourceCenter} />
      <Route path={ROUTES.PROFILE} component={Profile} />
      <Route path={ROUTES.CONTACT} component={Contact} />
      <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
      <Route path={'/'}><Redirect to={ROUTES.DASHBOARD} /></Route>
    </Switch>
  )
}

const UnauthenticatedApp = (props) => {
  return (
    <Switch>
      <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
      <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
      <Route path={ROUTES.CONTACT} component={Contact} />
      <Route path={`${ROUTES.SIGN_UP}/:invitationToken`} component={SignUp} />
      <Route path={ROUTES.SIGN_IN} component={SignIn} />
      <Route path={'/'}><Redirect to={ROUTES.SIGN_IN} /></Route>
    </Switch>
  )
}

const App = (props) => {
  const { sessionUser, loading } = useContext(SessionContext)

  if (loading) return null

  return (
    <Router>
      {sessionUser ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Router>
  )
}

export default App
