// @flow
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';

type DefaultData = *

type Props = {
  defaultData: ?DefaultData,
  onUpdate: DefaultData => void
};

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(4)
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  }
];

const valuetext = (value) => {
  return `${value}`;
}

const Goals = ({ defaultData, onUpdate }: Props): React$Node => {
  const classes = useStyles()
  const [data, setData] = useState(defaultData || {
    future: '',
    preferredCompanySize: '',
    preferredIndustry: '',
    careerMotivation: '',
    risk: 5
  })
  const [error, setError] = useState(undefined)

  const handleChange = e => {
    setError(undefined)
    setData(state => ({
      ...state,
      [e.target.name]: e.target.value
    }))
  }

  const handleSliderChange = (event, newValue) => {
    setData(state => ({
      ...state,
      ['risk']: newValue
    }))
  };

  // Pass updates to data back up to the parent component
  useEffect(() => {
    onUpdate(data)
  }, [data])

  return (
    <div>
      <Container>
        <form>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={10}>
              <Typography variant="h3">Your Goals</Typography>
              <Typography>Where would you like to go in your career?</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl className={classes.formControl}>
                <InputLabel>Where do you see yourself in 5-10 years?</InputLabel>
                <Select
                  value={data.future}
                  name="future"
                  onChange={handleChange}
                  label="Where do you see yourself in 5-10 years?"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='future-cio'>CIO</MenuItem>
                  <MenuItem value='future-cto'>CTO</MenuItem>
                  <MenuItem value='future-ceo'>CEO</MenuItem>
                  <MenuItem value='future-unsure'>Unsure</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <FormControl className={classes.formControl}>
                <InputLabel>What company size gets you excited?</InputLabel>
                <Select
                  value={data.preferredCompanySize}
                  name="preferredCompanySize"
                  onChange={handleChange}
                  label="What company size gets you excited?"
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='co-size-lg'>Large Public Corporation ($2B+)</MenuItem>
                  <MenuItem value='co-size-md'>Mid-stage or PE-backed</MenuItem>
                  <MenuItem value='co-size-sm'>Startup or High-growth</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <FormControl className={classes.formControl}>
                <InputLabel>What industry are you most interested in?</InputLabel>
                <Select
                  value={data.preferredIndustry}
                  name="preferredIndustry"
                  onChange={handleChange}
                  label="What industry are you most interested in?"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='industry-consumer'>Consumer Markets</MenuItem>
                  <MenuItem value='industry-finance'>Financial Services</MenuItem>
                  <MenuItem value='industry-global-tech'>Global Technology & Services</MenuItem>
                  <MenuItem value='industry-healthcare'>Healthcare & Life Sciences</MenuItem>
                  <MenuItem value='industry-industrial'>Industrial & Manufacturing</MenuItem>
                  <MenuItem value='industry-impact'>Social Impact</MenuItem>
                  <MenuItem value='industry-none'>Industry is not important</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <FormControl className={classes.formControl}>
                <InputLabel>What is your primary career motivator?</InputLabel>
                <Select
                  value={data.careerMotivation}
                  name="careerMotivation"
                  onChange={handleChange}
                  label="What is your primary career motivator?"
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='motivation-earning'>Maximizing earning potential</MenuItem>
                  <MenuItem value='motivation-responsibility'>Increasing scope of responsibility</MenuItem>
                  <MenuItem value='motivation-mission'>Contributing to an organization’s overall mission or goals</MenuItem>
                  <MenuItem value='motivation-build'>Opportunity to build</MenuItem>
                  <MenuItem value='motivation-recognition'>Public recognition for your accomplishments</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <Typography id="discrete-slider-custom" gutterBottom>
                What is your appetite for risk taking?
              </Typography>
              <Slider
                defaultValue={data.risk}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                onChange={handleSliderChange}
                step={1}
                valueLabelDisplay="auto"
                min={1}
                max={10}
                marks={marks}
              />
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  )
}

export default Goals
