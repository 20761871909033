// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withCardStyle } from 'services/decorators';
import SuccessProfileSection from './SuccessProfileSection';
import CompensationGraphSection from './CompensationGraphSection';

const DashboardCard = withCardStyle(Box);

const useStyles = makeStyles(theme => ({
  dashboardCard: {
    padding: theme.spacing(2, 3)
  }
}));

const ResourceCenter = (): React$Node => {
  const classes = useStyles();

  return (
    <Box width='100%'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>Resource Center</Typography>
        </Grid>
        <Grid item xs={12}>
          <DashboardCard className={classes.dashboardCard}>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <CompensationGraphSection />
              </Grid>
            </Grid>
          </DashboardCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResourceCenter;
