// @flow
import request from './ApiClient';
import type { SerializedApiCollection } from 'types/ApiTypes';
import type { Mentor } from 'types/MentorTypes';

const list = (params?: ?{ limit?: number }): Promise<SerializedApiCollection<Mentor>> => {
  return request({
    url: '/mentors',
    method: 'GET',
    params
  });
};

const MentorService = {
  list
};

export default MentorService;
