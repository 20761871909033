// @flow
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import clsx from 'clsx';
import { google, ics, yahoo } from 'calendar-link';
import { withCardStyle } from 'services/decorators';
import CalendarBlue from 'assets/svgs/calendarBlue.svg';
import GoogleCalendarIcon from 'assets/svgs/googleCalendarIcon.svg';
import OutlookCalendarIcon from 'assets/svgs/outlookCalendarIcon.svg';
import YahooCalendarIcon from 'assets/svgs/yahooCalendarIcon.svg';
import Lockable from 'components/Lockable';
import UpcomingEventsContext from 'components/UpcomingEvents';
import { useSessionUser } from 'hooks';

import { palette, fontSize } from 'constants/theme';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    margin: theme.spacing(1),
    fontWeight: 'normal',
  },
  card: {
    width: '100%',
  },
  actionCard: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  cardActions: {
    width: '100%'
  },
  calendarImage: {
    display: 'block',
    height: 20,
  },
  avatar: {
    background: 'none',
    color: palette.blue,
    fontSize: fontSize.h4,
    height: 50,
    width: 50
  },
  googleCalendar: {
    color: '#0e71eb',
    border: '1px solid #0e71eb',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  },
  outlookCalendar: {
    color: '#1071C1',
    border: '1px solid #1071C1',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  },
  yahooCalendar: {
    color: '#5B24CE',
    border: '1px solid #5B24CE',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  }
}));

const DashboardCard = withCardStyle(Card);

const UpcomingEventsSection = (): React$Node => {
  const classes = useStyles();
  const { upcomingEvents } = useContext(UpcomingEventsContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4'>Upcoming Events</Typography>
      </Grid>
      {upcomingEvents.length === 0 && (
        <Grid item xs={12}>
          <Typography style={{ marginTop: '8px' }}>No new events are scheduled</Typography>
        </Grid>
      )}
      {upcomingEvents.map(event => {
        const eventTime = moment(event.eventTime);
        const calendarEvent = {
          title: event.title,
          description: event.url,
          start: eventTime.format('YYYY-MM-DD h:mm:ss a'),
          duration: [1, 'hour'],
        };

        return (
          <Grid key={event.id} item xs={12}>
            <DashboardCard className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar}>
                    {eventTime.format('M/D')}
                  </Avatar>
                }
                title={<Typography variant='subtitle2'>{event.title}</Typography>}
                subheader={(
                  <div>
                    <Typography variant='body2'>{eventTime.format('dddd, MMM D [at] h:mma')}</Typography>
                    {event.url ? (
                      <div style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'center', marginTop: 10 }}>
                        <Button
                          size="small"
                          href={google(calendarEvent)}
                          target="_blank"
                          className={clsx(classes.button, classes.googleCalendar)}
                          startIcon={<img src={GoogleCalendarIcon} className={classes.calendarImage} />}
                        >
                          Google Calendar
                        </Button>
                        <Button
                          size="small"
                          href={ics(calendarEvent)}
                          target="_blank"
                          className={clsx(classes.button, classes.outlookCalendar)}
                          startIcon={<img src={OutlookCalendarIcon} className={classes.calendarImage} />}
                        >
                          Outlook Calendar
                        </Button>
                        <Button
                          size="small"
                          href={yahoo(calendarEvent)}
                          target="_blank"
                          className={clsx(classes.button, classes.yahooCalendar)}
                          startIcon={<img src={YahooCalendarIcon} className={classes.calendarImage} />}
                        >
                          Yahoo Calendar
                        </Button>
                      </div>
                    ) : (
                      <Typography variant='body2'>Details Coming Soon</Typography>
                    )}
                  </div>
                )}
              />
            </DashboardCard>
          </Grid>
        )
      })}
    </Grid>
  );
};

export default UpcomingEventsSection;
