// @flow
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import * as ROUTES from 'constants/routes';

export const TermsLink = (): React$Node => (
  <Link
    component={RouterLink}
    to={ROUTES.TERMS_AND_CONDITIONS}
    style={{ color: 'inherit' }}
    target={'_blank'}
  >
    Terms and Conditions
  </Link>
);

export const PrivacyLink = (): React$Node => (
  <Link
    component={RouterLink}
    to={ROUTES.PRIVACY_POLICY}
    style={{ color: 'inherit' }}
    target={'_blank'}
  >
    Privacy Policy
  </Link>
);

export const ContactLink = (): React$Node => (
  <Link
    component={RouterLink}
    to={ROUTES.CONTACT}
    style={{ color: 'inherit' }}
  >
    Contact Us
  </Link>
);

const PolicyLinks = ({ prefix = '', connector = ' & ', suffix = '', withContact = null }: { prefix?: string, connector?: string, suffix?: string, withContact?: boolean }): React$Node => (
  <>
    {prefix}
    <TermsLink />
    {connector}
    <PrivacyLink />
    {withContact && (
      <>
        {' • '}
        <ContactLink />
      </>
    )}
    {suffix}
  </>
);

export default PolicyLinks;
