// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ActionIconButton from 'components/ActionIconButton';
import { UserDecorator, EmailDecorator } from 'services/decorators';

import type { Mentor } from 'types/MentorTypes';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  listItem: {
    cursor: 'pointer',
    padding: theme.spacing(1, 10, 1, 1),
    '&:hover': {
      backgroundColor: 'rgba(0, 6, 46, 0.05)'
    }
  }
}));

const MentorList = ({ mentors }: { mentors: Array<Mentor> }): React$Node => {
  const classes = useStyles();

  return (
    <List className={classes.root} disablePadding>
      {mentors.map(peer => {
        const { id, avatarUrl, name, jobTitle, companyName, email, linkedinUrl } = peer;

        return (
          <ListItem key={id} className={classes.listItem} disableGutters>
            <ListItemAvatar>
              <Avatar src={avatarUrl} alt={name} />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>{name}</Typography>}
              secondary={<Typography variant='caption'>{UserDecorator.careerHeadline(jobTitle, companyName)}</Typography>}
            />
            <ListItemSecondaryAction>
              {linkedinUrl && <ActionIconButton href={linkedinUrl} icon='linkedin' />}
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  );
};

export default MentorList;
