// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import FullPageBox from 'components/FullPageBox';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    marginBottom: theme.spacing(2),
    textDecoration: 'underline'
  }
}));

const PrivacyPolicy = (): React$Node => {
  const classes = useStyles();

  return (
    <FullPageBox withNav overflow='scroll'>
    <Container className={classes.root} maxWidth='lg'>
      <Typography className={classes.sectionHeading}>Odyssey Privacy Notice</Typography>
      <Typography className={classes.marginBottom}>
        This Privacy Notice explains how your personal data will be collected and used by Heidrick and
        Struggles, Inc (“Heidrick”) in connection with Odyssey. Odyssey provides its users with relevant
        content, coaching, mentorship and networking opportunities.
      </Typography>
      <Typography className={classes.marginBottom}>
        This Privacy Notice is supplemental to our internal company policies and procedures at
        Heidrick. It relates solely to how your personal data will be handled in connection with Odyssey
        and is separate to any specific consent we may have obtained from you in connection with its
        use, if applicable.
      </Typography>
      <Typography className={classes.marginBottom}>
        Heidrick is the controller of the personal data collected through your use of Odyssey. Heidrick
        along with its subsidiaries and affiliates, provides global executive search and leadership
        consulting services and you can visit Heidrick’s main privacy policy <a href="https://www.heidrick.com/About/Privacy-Policy" target="_blank">here</a>.
      </Typography>
      <Typography className={classes.sectionHeading}>
        What data is collected?
      </Typography>
      <Typography className={classes.marginBottom}>
        As a registered user, the following information may be collected about you through your use of
        Odyssey.
      </Typography>
      <ul>
        <li><Typography>Information that you disclose to us through your audio and video recordings, such as
        your work history (including industry and organization level), skills, experience, abilities,
        work styles and other responses to interview questions or exercises during your use of
        the assessments. This also includes information that is learned or inferred through your
        use of ALP– voice (including tonality, words used and linguistic features) and image
        (including facial movement and expression)</Typography></li>
        <li><Typography>Previous background details obtained and stored within the Heidrick database during a
        prior interaction. This may include your name, email, phone number, age, gender,
        employment history, education and background. Heidrick processes this information as
        necessary to perform our contract with you to provide you with the Odyssey service.</Typography></li>
        <li><Typography>Information that you provide during account creation of Odyssey. This may include your
        name, email, phone number, LinkedIn profile information, city location, job title and
        company employer. Heidrick processes this information as necessary to perform our
        contract with you to provide you with the Odyssey service.</Typography></li>
        <li><Typography>Additional personal details that you may provide through your use of Odyssey. This may
        include your career goals, work challenges, topics of interest, coaching goals,
        assessment details and results, communications with other members, coaches or
        mentors and survey or feedback information. Heidrick also processes this information as
        necessary to perform our contract with you to provide you with the Odyssey service.</Typography></li>

        <li><Typography>If you sync your calendar or contacts to Odyssey, we will collect your meeting
        information to provide availability windows as part of the scheduling service and
        provide information about future events, and suggested users for networking purposes.
        Heidrick processes this information as necessary to perform our contract with you to
        provide you with the Odyssey service.</Typography></li>
        <li><Typography>If you register for Odyssey’s premium service, you will need to provide payment and
        billing information. Heidrick processes this information as it is necessary to perform our
        contract with you to provide you with the premium Odyssey service.</Typography></li>
        <li><Typography>Information collected automatically through your use of Odyssey. This may include your
        IP address, unique device IDs or addresses, web browser and/or device type, webpage
        views and the dates and times you visit, access or use Odyssey. Some of this information
        may be collected using cookies* and similar technologies. This usage data helps us
        understand how often you login, how you use Odyssey and your preferred network
        connections. Heidrick processes this information as necessary for our legitimate
        interests in evaluating and improving the Odyssey service.</Typography></li>
        <li><Typography>Cookies: We may log information using &quot;cookies.&quot; Cookies are small data files stored on
        your hard drive by a website. We may use both session Cookies (which expire once you
        close your web browser) and persistent Cookies (which stay on your computer until you
        delete them) to provide you with a more personal and interactive experience on our
        Site. This type of information is collected to make the Site more useful to you and to
        tailor the experience with us to meet your special interests and needs.</Typography></li>
      </ul>
      <Typography className={classes.marginBottom}>
        Cookie choices
      </Typography>
      <Typography className={classes.marginBottom}>
        In most cases we will need your consent in order to use cookies on the site; exceptions
        to this apply such as where the cookie is essential in order for us to provide you with a
        service you have requested. You can review your Internet browser settings, typically
        under &quot;Help&quot; or &quot;Internet Options&quot; for options you have to block certain Cookies. To
        learn more about Cookies used for interest based advertising, and to exercise certain
        choices you have regarding these Cookies, please visit the Digital Advertising
        Alliance, Digital Advertising Alliance-Canada, European Interactive Digital Advertising
        Alliance as applicable, or the Network Advertising Initiative. Please also visit the
        following specific links for additional information and choices on certain Cookies:
      </Typography>
      <Typography className={classes.marginBottom}>
        HEAP – we use HEAP to captures every user action on the website or mobile app for us
        to analyze the usage patterns. You acknowledge and accept that we have no control
        over HEAP’s data collection. More details about HEAP and Cookies can be found <a href="https://heap.io/privacy" target="_blank">here</a>.
      </Typography>
      <Typography className={classes.marginBottom}>
        Hotjar – we use Hotjar to help us to gain insight into your experience on the website by
        storing first-party cookies on your browser. You acknowledge and accept that we have
        no control over Hotjar’s data collection. More details about Hotjar and Cookies can be
        found <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies" target="_blank">here</a>.
      </Typography>
      <Typography className={classes.marginBottom}>
        Please note that by opting out of certain cookies, you will not be able to access
        important functions or enjoy certain conveniences of this website.
      </Typography>
      <Typography className={classes.sectionHeading}>
        How is your data processed?
      </Typography>
      <Typography className={classes.marginBottom}>
        Data collected through Odyssey is used to evaluate your background to provide targeted tools
        and content to help you reach your career apex. Professional background information assists in
        providing recommendations on potential future roles and meta-data related to those roles,
        such as compensation outlook.
      </Typography>
      <Typography className={classes.marginBottom}>
        We also use your data on Odyssey to match you manually or via matching algorithms with
        networking cohorts, relevant content and potential coaches for your professional development.
      </Typography>
      <Typography className={classes.marginBottom}>
        Additionally, we use completed assessment results to provide you with reporting and relevant
        content. Your assessment responses will be scored according to our system’s statistical model
        and a report with your results will be automatically generated and sent to you. Your results will
        not be provided to anyone other than yourself or a selected coach outside of Heidrick.
      </Typography>
      <Typography className={classes.marginBottom}>
        Heidrick may use aggregated and anonymized usage data from Odyssey, along with aggregated
        survey results, for internal research and development purposes. It may also use anonymized
        data for analytics purposes in connection with developing, enhancing, maintaining, supporting
        and providing the underlying technology in Odyssey.
      </Typography>
      <Typography className={classes.marginBottom}>
        Data provided through your use of Odyssey Heidrick may be included in our database for use as
        part of future partner placement searches. Any data and assessment results that may be
        utilized as a part of future search placement activity will not be used as a sole determinant for
        the hiring/selection decision.
      </Typography>
      <Typography className={classes.sectionHeading}>
        How long is your personal data kept?
      </Typography>
      <Typography className={classes.marginBottom}>
        We generally retain your data as long as you keep your account open. This includes data you
        provided or generated from use of your services.
      </Typography>
      <Typography className={classes.sectionHeading}>
        Who is your personal data shared with?
      </Typography>
      <Typography className={classes.marginBottom}>
        Your data is not shared with any 3rd parties, and is only accessible by Heidrick’s Hlab, Hunt Club
        (platform partner), Envisia (for the LAQ), and HireVue (for use of ALP).
      </Typography>
      <Typography className={classes.marginBottom}>
        In some cases the personal data collected from you might be processed by Heidrick outside the
        European Economic Area and United Kingdom, such as in the United States. We will implement
        adequate legal safeguards (such as Standard Contractual Clauses) to ensure that your data is
        protected. If you would like more information about the adequate legal safeguards in place,
        please contact us.
      </Typography>
      <Typography className={classes.marginBottom}>
        There are appropriate technical and organizational measures in place to safeguard your data
        and to protect your data from accidental or unlawful destruction or accidental loss, alteration,
        disclosure, or access, such as access controls and encrypting your data in transit and at rest.
      </Typography>
      <Typography className={classes.sectionHeading}>
        Your rights
      </Typography>
      <Typography className={classes.marginBottom}>
        Under applicable data protection laws, you may have the following data protection rights in
        respect to your personal data collected through Odyssey:
      </Typography>
      <ul>
        <li><Typography>The right to access and obtain a copy of personal data about you, as well as information
        relating to how and why we process it.</Typography></li>
        <li><Typography>The right to correct or update personal data about you that is inaccurate or incomplete.</Typography></li>
        <li><Typography>The right to restrict or limit the ways in which we process personal data about you,
        unless we have a legitimate interest in processing that data which may override a
        request that you make.</Typography></li>
        <li><Typography>The right to object to the processing of personal data about you where we rely on a
        legitimate interest (or those of a third party) and there is something about your
        particular situation which makes you want to object to processing on this ground.</Typography></li>
        <li><Typography>The right to ask us to erase personal data about you where there is no good reason for
        us continuing to process it. You also have the right to ask us to delete or remove your
        personal data where you have exercised your right to object to processing (as above).</Typography></li>
        <li><Typography>The right to obtain a copy of personal data about you in an easily accessible format and
        the right to transmit that personal data to another entity.</Typography></li>
        <li><Typography>The right not to be subject to a decision based solely on automated processing,
        including profiling, which produces legal or similarly significant effects on you.</Typography></li>
      </ul>
      <Typography className={classes.marginBottom}>
        If we have collected and are processing your personal data with your consent, then you may
        withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of
        any processing we conducted prior to your withdrawal, nor will it affect processing of your
        personal data conducted in reliance on lawful processing grounds other than consent.
      </Typography>
      <Typography className={classes.marginBottom}>
        To exercise any of these rights, please contact us directly on odyssey@heidrick.com.
      </Typography>
      <Typography className={classes.sectionHeading}>
        California Privacy Notice and Rights
      </Typography>
      <Typography className={classes.marginBottom}>
        We do not “sell” (as defined by the California Consumer Privacy Act) your personal data without
        your consent. We also do not rent, sell, or share personal data (as defined by California Civil
        Code §1798.83) with other people or unaffiliated companies for their direct marketing
        purposes.
      </Typography>
      <Typography className={classes.marginBottom}>
        California consumers have the right to request the deletion of their personal data, additional
        information about our use and disclosure of their personal data and the specific pieces of
        personal data we have about them. California consumers also have the right not to receive
        discriminatory treatment if they exercise the rights list above.
      </Typography>
      <Typography className={classes.marginBottom}>
        To exercise any of these rights, please contact us directly on Odyssey@Heidrick.com. We may
        need to ask you to provide their name, email, location, and telephone number to verify your
        request. California law permits California consumers to use an authorized agent to make
        privacy rights requests, in which case we will need them to provide us with proof of the
        California consumer’s written permission that shows the authorized agent has the authority to
        submit the request. An authorized agent must follow this process, and we may additionally
        require them to verify their identity and registration to do business in California.
      </Typography>
      <Typography className={classes.marginBottom}>
        If you have any questions or concerns about our use of your personal data, please contact us in
        the first instance. You also have the right to lodge a complaint with the regulator responsible
        for data protection in your country.
      </Typography>
      <Typography className={classes.marginBottom}>
        We may update this Privacy Notice from time to time and will notify you of any material
        changes.
      </Typography>
    </Container>
  </FullPageBox>
  );
};

export default PrivacyPolicy;
