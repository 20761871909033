// @flow
import request from './ApiClient';

import type { SerializedApiObject } from 'types/ApiTypes';
import type { User } from 'types/UserTypes';

const create = (data: { email: string, password: string }): Promise<SerializedApiObject<User>> => {
  return request({
    url: '/auth/sign_in',
    method: 'POST',
    data
  });
};

const destroy = (): Promise<SerializedApiObject<{}>> => {
  return request({
    url: '/auth/sign_out',
    method: 'DELETE'
  });
};

const SessionService = {
  create,
  destroy
};

export default SessionService;
