// @flow

const camelToSnakeCase = str => {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

const snakeCaseKeys = (obj: {}): {} => {
  let map = new Map(Object.entries(obj))
  let newObj = {}
  map.forEach((value, key) => {
    newObj[camelToSnakeCase(key)] = value
  })
  return newObj
}

export { snakeCaseKeys }
