// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import EmailIcon from 'assets/svgs/emailIcon.svg';
import LinkedInIcon from 'assets/svgs/linkedInIcon.svg';
import SlackIcon from 'assets/pngs/slackIcon.png';

type Props = {|
  icon: 'linkedin' | 'slack' | 'email',
  onClick?: () => void,
  href?: string,
  edge?: 'start' | 'end' | boolean
|};

const useStyles = makeStyles(theme => ({
  actionButton: {
    width: '22px',
    height: '22px'
  },
  actionIcon: {
    width: '16px',
    height: '16px',
    borderRadius: '2px'
  }
}));

const icons = {
  linkedin: LinkedInIcon,
  slack: SlackIcon,
  email: EmailIcon
}

const ActionIconButton = ({ icon, ...rest }: Props): React$Node => {
  const classes = useStyles();

  return (
    <IconButton className={classes.actionButton} { ...rest }>
      <img src={icons[icon]} className={classes.actionIcon} />
    </IconButton>
  )
};

export default ActionIconButton;
