// @flow
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import PeerList from 'components/PeerList';
import PeerListModal from 'components/PeerListModal';
import { PeerService } from 'services/api';

const limit = 3;

const NetworkPeerList = (): React$Node => {
  const [peers, setPeers] = useState([]);
  const [peersModalOpen, setPeersModalOpen] = useState(false);

  useEffect(() => {
    PeerService.list({ limit })
      .then(response => setPeers(response.data.map(d => d.attributes)))
  }, []);

  return (
    <>
      <PeerList peers={peers} />
      {peers.length >= limit && (
        <Link variant='caption' align='center' display='block' onClick={() => setPeersModalOpen(true)}>
          See All
        </Link>
      )}
      <PeerListModal isOpen={peersModalOpen} onClose={() => setPeersModalOpen(false)} />
    </>
  );
};

export default NetworkPeerList;
