// @flow
import request from './ApiClient';

import type { SerializedApiObject } from 'types/ApiTypes';
import type { User } from 'types/UserTypes';

const validateToken = (): Promise<SerializedApiObject<User>> => {
  return request({
    url: '/auth/validate_token',
    method: 'GET'
  });
};

const TokenValidationService = {
  validateToken
};

export default TokenValidationService;
