// @flow
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import SlackLogo from 'assets/pngs/slackLogo.png';

import type { SlackChannel } from 'types/SlackChannelTypes'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  listItem: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(0, 6, 46, 0.05)'
    }
  }
}));

type Props = {
  channels: Array<SlackChannel>
}

const SlackChannelList = ({ channels }: Props): React$Node => {
  const classes = useStyles();

  const onClick = url => {
    window.open(url, '_blank');
  };

  return (
    <List className={classes.root} disablePadding>
      {channels.map(channel => (
        <ListItem key={channel.id} className={classes.listItem} onClick={() => onClick(channel.url)}>
          <ListItemAvatar>
            <Avatar src={SlackLogo} alt={'Slack'} />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant='subtitle2'>{channel.name}</Typography>}
            secondary={<Typography variant='caption'>{channel.caption || 'Click here to join this slack channel'}</Typography>}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SlackChannelList;
