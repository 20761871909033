// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { withCardStyle } from 'services/decorators';
import CompensationModal, { CompensationGraph } from 'components/CompensationModal';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  card: {
    width: '100%',
    height: '206px',
    borderRadius: '6px',
    border: 'solid 1px rgba(0, 6, 46, 0.25)',
    padding: theme.spacing(3)
  },
  dataLink: {
    textAlign: 'right'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px'
  },
  titleContainer: {
    display: 'flex'
  }
}));

const DashboardCard = withCardStyle(Box);

const cioDisclaimer = 'Data drawn from placements made by Heidrick as well as data collected through the course of business.  It is intended to provide a current snapshot of market trends, and is provided “as is.”  Heidrick makes no warranties, representations or guarantees of any kind concerning this information.'

const CompensationGraphSection = (): React$Node => {
  const classes = useStyles();
  const [compensationModalOpen, setCompensationModalOpen] = useState(false);

  return (
    <>
      <Grid container alignItems='center'>
        <Grid item xs={6} className={classes.titleContainer}>
          <Typography variant='h6'>CIO Compensation</Typography>
          <Box className={classes.icon}><Tooltip title={cioDisclaimer}><InfoOutlinedIcon fontSize='inherit' /></Tooltip></Box>
        </Grid>
        <Grid item xs={6} className={classes.dataLink}>
          <Link onClick={() => setCompensationModalOpen(true)} variant='caption'>
            See Full Data Set
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.card}>
            <CompensationGraph
              role='CIO'
              isInteractive
            />
          </Box>
        </Grid>
      </Grid>
      <CompensationModal
        isOpen={compensationModalOpen}
        onClose={() => setCompensationModalOpen(false)}
        role='CIO'
      />
    </>
  );
};

export default CompensationGraphSection;
