// @flow
import React, { useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useIsOpen } from 'hooks';
import { SessionContext } from 'components/Session';
import { palette } from 'constants/theme';
import * as ROUTES from 'constants/routes';

const useStyles = makeStyles(theme => ({
  button: {
    color: palette.white
  },
  optionLabel: {
    width: '100%'
  }
}));

const options = [
  { label: 'Your Dashboard', url: ROUTES.DASHBOARD },
  { label: 'Your Profile', url: ROUTES.PROFILE },
  { label: 'Resource Center', url: ROUTES.RESOURCE_CENTER },
  { label: 'Terms and Conditions', url: ROUTES.TERMS_AND_CONDITIONS },
  { label: 'Privacy Policy', url: ROUTES.PRIVACY_POLICY },
  { label: 'Contact Us', url: ROUTES.CONTACT }
];

const Nav = (): React$Node => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { sessionUser, signOut } = useContext(SessionContext);
  const { isOpen, open, close } = useIsOpen(false);
  const anchorRef = useRef();
  const currentOption = options.find(option => option.url === location?.pathname);
  const popoverOptions = options.filter(option => option.url !== location?.pathname);
  const buttonIcon = isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  const handleClick = option => {
    history && history.push(option.url);
    close();
  };

  if (!sessionUser) return null;

  return (
    <>
      <Button variant='text' onClick={open} className={classes.button} endIcon={buttonIcon} ref={anchorRef}>
        {currentOption?.label}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorRef?.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {popoverOptions.map(option => (
            <ListItem button key={option.label} onClick={() => handleClick(option)}>
              <Typography
                variant='subtitle1'
                align='right'
                className={classes.optionLabel}
              >
                {option.label}
              </Typography>
            </ListItem>
          ))}
          <ListItem button onClick={signOut}>
            <Typography
              variant='subtitle1'
              align='right'
              className={classes.optionLabel}
            >
              Log Out
            </Typography>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default Nav;
