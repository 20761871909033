// @flow
export const palette = {
  black: '#00062E',
  blue: '#5D8AA3',
  white: '#ffffff',
};

// This is using base size of 16px
export const fontSize = {
  xsmall: '0.625rem', // 10px
  small: '0.75rem', // 12px
  medium: '0.875rem', // 14px
  h6: '0.875rem', // 14px
  default: '1rem', // 16px
  h5: '1rem', // 16px
  h4: '1.25rem', // 20px
  h3: '1.375rem', // 22px
  h2: '1.625rem', // 26px
  h1: '2rem', // 32px
}

export const fontWeight = {
  normal: 400,
  medium: 500,
  bold: 700
}

export const height = {
  navbar: '70px'
}
