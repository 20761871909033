// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}));

export type SimpleSelectProps = {
  value: string,
  onChange: SyntheticInputEvent<EventTarget> => void,
  error?: boolean,
  helperText?: string,
  id?: string,
  label?: string,
  name?: string,
  required?: boolean
};

type Option = { id: string | number, label: string, [string]: ?string };
type Props = SimpleSelectProps & { options: Array<Option>, renderOption?: Option => React$Node }

const SimpleSelect = ({
  value,
  onChange,
  error,
  helperText,
  id = 'selectId',
  label,
  name,
  options,
  required,
  renderOption
}: Props): React$Node => {
  const classes = useStyles();
  const safeValue = value || '';

  const renderCustomOption = (option: Option) => {
    if (renderOption) return renderOption(option);

    return <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
  };

  return (
    <FormControl className={classes.root} error={error}>
      {label && <InputLabel id={`label-${id}`}>{label}</InputLabel>}
      <Select
        labelId={`label-${id}`}
        id={id}
        label={label}
        name={name}
        value={safeValue}
        onChange={onChange}
        required={required}
      >
        {options.map(option => renderCustomOption(option))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SimpleSelect;
