// @flow
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import Modal from 'components/Modal';
import PeerList from 'components/PeerList';

import { PeerService } from 'services/api';

const PeerListModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }): React$Node => {
  const [peers, setPeers] = useState([]);

  useEffect(() => {
    if (!isOpen) return;

    PeerService.list()
      .then(response => {
        setPeers(response.data.map(d => d.attributes));
      });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth='sm'>
      <Typography variant='h3' gutterBottom>Peers</Typography>
      <PeerList peers={peers} />
    </Modal>
  )
};

export default PeerListModal;
