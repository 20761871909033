// @flow
import request from './ApiClient';
import type { SerializedApiCollection } from 'types/ApiTypes';
import type { Peer } from 'types/UserTypes';

const list = (params?: { limit?: number }): Promise<SerializedApiCollection<Peer>> => {
  return request({
    url: '/peers',
    method: 'GET'
  });
};

const PeerService = {
  list
};

export default PeerService;
