// @flow
import React, { useState, useEffect } from 'react';

import { UpcomingEventService } from 'services/api';
import type { Event } from 'types/EventTypes';

const UpcomingEventsContext: Object = React.createContext<{
  upcomingEvents: Array<Event>,
  refresh: () => void
}>({
  upcomingEvents: [],
  refresh: () => {}
});

export default UpcomingEventsContext;

export const UpcomingEventsProvider = ({ children }: { children: React$Node }): React$Node => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    if (upcomingEvents.length > 0) return;

    refresh();
  }, []);

  const refresh = () => {
    UpcomingEventService.list({ limit: 4 })
      .then(response => setUpcomingEvents(response.data.map(d => d.attributes)));
  };

  const value = {
    upcomingEvents,
    refresh
  };

  return (
    <UpcomingEventsContext.Provider
      value={value}
      children={children}
    />
  );
};
