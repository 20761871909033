// @flow
export const companySizes = [
  { id: 'all', label: 'All Company Sizes', dataValue: null, subtext: null },
  { id: 'largeCap', label: 'Large Cap', dataValue: 'Large Cap', subtext: '$10B+' },
  { id: 'midCap', label: 'Mid Cap', dataValue: 'Mid Cap', subtext: '$2B - $10B' },
  { id: 'smallCap', label: 'Small Cap', dataValue: 'Small Cap', subtext: '$300M - $2B' },
  { id: 'microCap', label: 'Micro Cap', dataValue: 'Micro Cap', subtext: '$50M - $300M' },
  { id: 'nanoCap', label: 'Nano Cap', dataValue: 'Nano Cap', subtext: '< $50M' }
];

export const industries = [
  { id: 'all', label: 'All Industries', dataValue: null },
  { id: 'socialImpact', label: 'Social Impact', dataValue: 'Social Impact' },
  { id: 'industrial', label: 'Industrial', dataValue: 'Industrial' },
  { id: 'globalTech', label: 'Global Technology & Services', dataValue: 'Global Technology & Services' },
  { id: 'financial', label: 'Financial Services', dataValue: 'Financial Services' },
  { id: 'consumerMarket', label: 'Consumer Markets', dataValue: 'Consumer Markets' },
  { id: 'healthcare', label: 'Healthcare and Life Sciences', dataValue: 'Healthcare and Life Sciences' },
]

export const states = [
  { id: 'AL', label: "Alabama" },
  { id: 'AK', label: "Alaska" },
  { id: 'AS', label: "American Samoa" },
  { id: 'AZ', label: "Arizona" },
  { id: 'AR', label: "Arkansas" },
  { id: 'CA', label: "California" },
  { id: 'CO', label: "Colorado" },
  { id: 'CT', label: "Connecticut" },
  { id: 'DE', label: "Delaware" },
  { id: 'DC', label: "District Of Columbia" },
  { id: 'FM', label: "Federated States Of Micronesia" },
  { id: 'FL', label: "Florida" },
  { id: 'GA', label: "Georgia" },
  { id: 'GU', label: "Guam" },
  { id: 'HI', label: "Hawaii" },
  { id: 'ID', label: "Idaho" },
  { id: 'IL', label: "Illinois" },
  { id: 'IN', label: "Indiana" },
  { id: 'IA', label: "Iowa" },
  { id: 'KS', label: "Kansas" },
  { id: 'KY', label: "Kentucky" },
  { id: 'LA', label: "Louisiana" },
  { id: 'ME', label: "Maine" },
  { id: 'MH', label: "Marshall Islands" },
  { id: 'MD', label: "Maryland" },
  { id: 'MA', label: "Massachusetts" },
  { id: 'MI', label: "Michigan" },
  { id: 'MN', label: "Minnesota" },
  { id: 'MS', label: "Mississippi" },
  { id: 'MO', label: "Missouri" },
  { id: 'MT', label: "Montana" },
  { id: 'NE', label: "Nebraska" },
  { id: 'NV', label: "Nevada" },
  { id: 'NH', label: "New Hampshire" },
  { id: 'NJ', label: "New Jersey" },
  { id: 'NM', label: "New Mexico" },
  { id: 'NY', label: "New York" },
  { id: 'NC', label: "North Carolina" },
  { id: 'ND', label: "North Dakota" },
  { id: 'MP', label: "Northern Mariana Islands" },
  { id: 'OH', label: "Ohio" },
  { id: 'OK', label: "Oklahoma" },
  { id: 'OR', label: "Oregon" },
  { id: 'PW', label: "Palau" },
  { id: 'PA', label: "Pennsylvania" },
  { id: 'PR', label: "Puerto Rico" },
  { id: 'RI', label: "Rhode Island" },
  { id: 'SC', label: "South Carolina" },
  { id: 'SD', label: "South Dakota" },
  { id: 'TN', label: "Tennessee" },
  { id: 'TX', label: "Texas" },
  { id: 'UT', label: "Utah" },
  { id: 'VT', label: "Vermont" },
  { id: 'VI', label: "Virgin Islands" },
  { id: 'VA', label: "Virginia" },
  { id: 'WA', label: "Washington" },
  { id: 'WV', label: "West Virginia" },
  { id: 'WI', label: "Wisconsin" },
  { id: 'WY', label: "Wyoming"  }
];
