// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withCardStyle } from 'services/decorators';
import SuccessProfileModal from 'components/SuccessProfileModal';
import { useIsOpen } from 'hooks';

import { palette } from 'constants/theme';

const successProfileData = [
  {
    id: 1,
    profileName: 'Chief Information Officer',
    title: 'The Modern CIO',
    description: "<p>The role of the CIO is to help develop and lead the technology strategy and act as an interface between technology and the organization as a whole. Given the increasing importance of technology and how it can reshape industries, the role of the CIO has grown in both popularity and importance.</p><p>This role is key in setting the broad IT strategy for the organization, and ensuring its success with the larger business strategy. A CIO must be able to understand business requirements and how to prioritize through the use of technology.</p>",
    sections: [
      {
        label: 'Experience',
        content: "<ul><li>Data leadership and a demonstrated ability to define strategy and guide a technical team towards results</li><li>Successful track record of data program deployment and ability to improve processes around data analytics</li><li>Strong understanding of data and information architecture; strong data modeling and technical skills</li><li>Experience with unstructured data, big data environments, relational databases, streaming, and batch data processing</li></ul>"
      },
      {
        label: 'Expertise',
        content: "<ul><li>Experience with unstructured data, big data environments, relational databases, streaming, and batch data processing</li><li>Exceptional collaboration, listening, written and verbal communication skills with ability to facilitate technology requirements with all stakeholders including management, business users, and technology resources</li><li>Ability to design and build large-scale, cutting edge technology and analytic platforms to align with the company's strategic objectives</li><li>Translate analytical findings and tools to senior business leaders, partners, and clients which involves communicating complex concepts to a wide audience</li>"
      },
      {
        label: 'Leadership Profile',
        content: "<ul><li>Ability to shape strategy by setting strategic priorities around data and analytics, and can envision and anticipate future technologies to position the organization for continuous improvement</li><li>Customer first leadership style that allows for deep relationships to be built with internal and external clients</li><li>Forward thinking and hands-on leader who builds talent and teams - builds and develops data and analytics teams, recruiting top talent to the organization</li></ul>"
      }
    ]
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  card: {
    width: '100%',
    borderRadius: '6px',
    border: 'solid 1px rgba(0, 6, 46, 0.25)',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: '66px',
    height: '66px',
    borderRadius: '50%',
    backgroundColor: palette.blue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.white,
    marginBottom: theme.spacing(2)
  },
  header: {
    lineHeight: 1.43,
    marginBottom: theme.spacing(2)
  },
  link: {
    color: palette.blue,
    cursor: 'pointer'
  }
}));

const DashboardCard = withCardStyle(Box);

const SuccessProfileSection = (): React$Node => {
  const [successProfile, setSuccessProfile] = useState(successProfileData[0]);
  const { isOpen, open, close } = useIsOpen(false);
  const classes = useStyles();

  const profileAvatarText = successProfile.profileName.split(' ').map(part => part[0]).join('');

  return (
    <>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant='h6'>Success Profiles</Typography>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.card}>
            <Box className={classes.avatar}>
              <Typography variant='h6' color='inherit'>{profileAvatarText}</Typography>
            </Box>
            <Typography align='center' variant='h6' className={classes.header}>{`Explore what makes a world-class ${successProfile.profileName}`}</Typography>
            <Link onClick={open}>
              <Typography align='center' variant='caption' className={classes.link}>
                View Full Leadership Profile >
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <SuccessProfileModal profile={successProfile} isOpen={isOpen} onClose={close} />
    </>
  );
};

export default SuccessProfileSection;
