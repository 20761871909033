// @flow
import request from './ApiClient';
import type { SerializedApiCollection } from 'types/ApiTypes';
import type { Event } from 'types/EventTypes';

const list = (params?: ?{ limit?: number }): Promise<SerializedApiCollection<Event>> => {
  return request({
    url: `/upcoming_events`,
    method: 'GET',
    params
  });
};

const UpcomingEventService = {
  list
};

export default UpcomingEventService;
