// @flow
import type { User, Peer } from 'types/UserTypes';

export default class UserDecorator {
  static careerHeadline(jobTitle: ?string, companyName: ?string): string {
    if (!jobTitle) return '';
    if (!companyName) return jobTitle;

    return `${jobTitle} at ${companyName}`;
  }
};
