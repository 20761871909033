// @flow
import request from './ApiClient';

import type { SerializedApiObject } from 'types/ApiTypes';

const create = (email: string): Promise<{ message: string }> => {
  return request({
    url: '/auth/password',
    method: 'POST',
    data: { email }
  });
};

const update = (data: { reset_password_token: string, password: string, password_confirmation: string }): Promise<{}> => {
  return request({
    url: '/auth/password',
    method: 'PATCH',
    data
  });
};

const PasswordService = {
  create,
  update
};

export default PasswordService;
