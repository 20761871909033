// @flow
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

import MentorList from 'components/MentorList';
import { MentorService } from 'services/api';

const limit = 3;

const NetworkMentorPreviewList = (): React$Node => {
  const [mentors, setMentors] = useState([]);

  useEffect(() => {
    MentorService.list({ limit })
      .then(response => setMentors(response.data.map(d => d.attributes)))
  }, []);

  return (
    <>
      <MentorList mentors={mentors} />
      <Button
        size='small'
        fullWidth
        disabled
      >
        Request Meeting (Coming Soon)
      </Button>
    </>
  );
};

export default NetworkMentorPreviewList;
