// @flow
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

import { companySizes } from 'constants/selectOptions';
import SimpleSelect from './SimpleSelect';

import type { SimpleSelectProps } from './SimpleSelect';

const CompanySizeSelect = (props: SimpleSelectProps): React$Node => {
  return (
    <SimpleSelect
      {...props}
      id={props.id || 'companySize'}
      name={props.name || 'companySize'}
      label={props.label || 'Company Size'}
      options={companySizes}
      renderOption={option => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
          {option.subtext && (
            <span style={{ fontSize: 10, marginLeft: 5 }}>({option.subtext})</span>
          )}
        </MenuItem>
      )}
    />
  );
};

export default CompanySizeSelect;
