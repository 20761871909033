// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import NavPopover from './NavPopover';
import LogoWhite from 'assets/svgs/logoWhite.svg';
import { height } from 'constants/theme';
import * as ROUTES from 'constants/routes';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundImage: 'radial-gradient(circle at 50% 0, #124968, #00062e 95%)',
    maxHeight: height.navbar
  },
  toolBar: {
    padding: theme.spacing(2, 6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 'initial'
  },
  logo: {
    width: '160px'
  }
}));

const Nav = (): React$Node => {
  const classes = useStyles();

  return (
    <AppBar position='sticky' className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Link to={ROUTES.DASHBOARD} style={{ textDecoration: 'none' }}>
          <img src={LogoWhite} alt="Odyssey Logo" className={classes.logo} />
        </Link>
        <NavPopover />
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
