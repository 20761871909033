// @flow
import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';

import { useSessionUser, useNotification } from 'hooks';
import UpcomingEventsContext from 'components/UpcomingEvents';
import { FreeBusyDecorator } from 'services/decorators';
import { EventService } from 'services/api';

type Props = {|
  size?: string,
  fullWidth?: boolean,
  disabled?: boolean,
  children: React$Node
|};

const pickATimeLink = process.env.REACT_APP_FREE_BUSY_COACHING_SESSION_URL;

const BookCoachingSessionButton = (props: Props): React$Node => {
  const sessionUser = useSessionUser();
  const { refresh } = useContext(UpcomingEventsContext);
  const { notify } = useNotification();

  useEffect(() => {
    // $FlowExpectedError
    freebusy.pickatime.init({
      buttonType: 'none'
    });
  }, []);

  const onClick = e => {
    // $FlowExpectedError
    freebusy.pickatime.open({
      link: pickATimeLink,
      display: 'modal',
      branding: 'hidden'
    },
    {
      participants: [{
        email: sessionUser.email,
        name: sessionUser.name,
        role: 'proposer'
      }]
    });

    freebusy.pickatime.onclose((response, proposal) => {
      // If status is not 200, then meeting was not scheduled/user closed modal
      if (response.statusCode !== 200) return;

      const { id, organizer, startTime } = proposal;

      EventService.create({
        title: `Coaching Session with ${organizer.name}`,
        eventTime: startTime,
        url: FreeBusyDecorator.meetingUrl(id),
        userEventsAttributes: [{ user_id: sessionUser.id }]
      }).then(() => {
        refresh();
        sessionUser.updateTrackedActions({ bookedCoachingSession: true });
        notify(`Your coaching session with ${organizer.name} has been booked!`);
      });
    });
  };

  if (!pickATimeLink) return null;

  return (
    <Button
      {...props}
      id={'pickATimeMentors'}
      onClick={onClick}
    />
  );
};

export default BookCoachingSessionButton;
