// @flow
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LogoWhite from 'assets/svgs/logoWhite.svg'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import { Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty';

// Import onboarding sub-components
import WelcomeCarousel from './WelcomeCarousel';
import Basics from './Basics'
import Goals from './Goals'
import Challenges from './Challenges'
import Strengths from './Strengths'
import Review from './Review'
import Confirmation from './Confirmation'
import { UserService } from 'services/api'
import Stepper from 'components/Stepper';
import { useSessionUser } from 'hooks';
import moment from 'moment';
import * as ROUTES from 'constants/routes';
import LoginBackground from 'assets/svgs/loginBackground.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh'
  },
  wizardContext: {
    background: `url(${LoginBackground}) no-repeat`,
    backgroundSize: 'cover',
    position: 'relative'
  },
  wizardText: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: '#ffffff',
    textAlign: 'center'
  },
  wizardTextContent: {
    width: 540,
    maxWidth: '100%',
    padding: 20
  },
  logoContainer: {
    padding: 40
  },
  logo: {
    width: 160
  },
  wizard: {
    backgroundColor: '#ffffff',
    height: '100%',
    overflowY: 'scroll'
  },
  ctaContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'red'
  },
  stepperContainer: {
    padding: theme.spacing(3)
  }
}));

function getSteps() {
  return ['Basics', 'Goals', 'Challenges', 'Strengths', 'Review']
}

function getStepHeaders() {
  return [
    'Welcome to Odyssey',
    '',
    'Where do you want to be?',
    'What obstacles are you facing?',
    'What are you great at?',
    'Heidrick Coaching',
    'Onboarding Complete!'
  ]
}

function getStepTexts() {
  return [
    <>
      <Typography style={{ marginBottom: '32px' }}>{`You have been selected for Heidrick & Struggles' exclusive pilot program to receive unprecedented access to H&S coaches, tools, and contacts.`}</Typography>
      <Typography>To help us customize your experience, please complete the following questions.</Typography>
    </>,
    '',
    'Help us help you. To get the most out of your Odyssey experience, tell us a bit about your professional goals. We use this information to tailor your Odyssey experience and connect you with others to help you reach your&nbsp;goals.',
    'Whether it\'s managing internal politics, building a team, or developing new technical skills, you’re navigating new roadblocks daily. Tell us about the primary challenges you’re facing right&nbsp;now.',
    'Your track record is impressive. That\'s why you\'re here. Tell us about your greatest strengths, so we can connect you with others in the Odyssey community who share similar interests or who could benefit from your expertise.',
    'As a key part of your Odyssey experience, you\'ll be paired with some of the best business coaches in the world. Heidrick coaches have helped groom and shape startup founders, Fortune 500 executives, and the top leaders in their&nbsp;field.',
    'Welcome to the Odyssey community. You are joining an elite group of tomorrow\'s C-suite. Your peers here are high performing leaders who are dedicated to reaching the apex of their&nbsp;careers.'
  ]
}

const Onboarding = (): React$Node => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState(undefined);
  const steps = getSteps();
  const texts = getStepTexts();
  const headers = getStepHeaders();
  const sessionUser = useSessionUser();

  useEffect(() => {
    UserService.fetch(sessionUser.id).then(response => {
      const userAttributes = response.data.attributes

      // Skip welcome carousel if viewed onboarding
      if (userAttributes.viewedOnboardingAt !== null) {
        setActiveStep(1)
      }

      setUser(userAttributes)
      setData([
        {
          name: userAttributes.name,
          linkedinUrl: userAttributes.linkedinUrl,
          city: userAttributes.city,
          state: userAttributes.state,
          jobTitle: userAttributes.jobTitle,
          companyName: userAttributes.companyName
        },
        userAttributes.preferences?.goals,
        userAttributes.preferences?.challenges,
        userAttributes.preferences?.strengths
      ])
    })
  }, []);

  // Return early until the user and data objects are set
  if (!user || !data) return null;

  const handleNext = () => {
    if (activeStep === 0) {
      // Set welcome carousel viewed
      UserService.update(sessionUser.id, { viewedOnboardingAt: moment() })
    } else {
      const updateData = data[activeStep - 1];

      if (updateData) {
        if (activeStep === 1) {
          // Update basic user data
          if (validateBasics(updateData)) {
            UserService.update(sessionUser.id, updateData);
          } else {
            return null;
          }

        } else if (activeStep === 2) {
          // Update user goals
          UserService.updatePreferences(sessionUser.id, 'goals', updateData)
        } else if (activeStep === 3) {
          // Update user challenges
          UserService.updatePreferences(sessionUser.id, 'challenges', updateData)
        } else if (activeStep === 4) {
          // Update user strengths
          UserService.updatePreferences(sessionUser.id, 'strengths', updateData)
        }
      }
    }

    setErrors(undefined);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setSubData = (step, update) => {
    let currentData = data ? [...data] : []
    let index = step - 1
    currentData[index] = update
    setData(currentData);
  }

  const getStepContent = (step) => {
    let index = step - 1
    switch (step) {
      case 0:
        return <WelcomeCarousel />
      case 1:
        return <Basics defaultData={data[index]} onUpdate={update => setSubData(step, update)} errors={errors} />
      case 2:
        return <Goals defaultData={data[index]} onUpdate={update => setSubData(step, update)}/>
      case 3:
        return <Challenges defaultData={data[index]} onUpdate={update => setSubData(step, update)}/>
      case 4:
        return <Strengths defaultData={data[index]} onUpdate={update => setSubData(step, update)}/>
      case 5:
        return <Review user={user}/>
      default:
        return <Redirect to={ROUTES.DASHBOARD} />
    }
  };

  const validateBasics = (updateData: *) => {
    if (!updateData) return false;

    const newErrors = {};
    const attributes = ['name', 'city', 'state', 'jobTitle', 'companyName'];

    attributes.forEach(attribute => {
      if (isEmpty(updateData[attribute])) {
        newErrors[attribute] = 'This field is required';
      }
    });

    if (isEmpty(newErrors)) {
      return true
    }

    setErrors(newErrors);
    return false;
  };

  const renderStepButtons = () => {
    if (activeStep === 0) {
      return (
        <Button
          size="large"
          onClick={handleNext}
          style={{ flex: 1, maxWidth: 300 }}
        >
          Begin Onboarding
        </Button>
      );
    }

    return (
      <>
        <Button
          variant="contained"
          size="large"
          disabled={activeStep === 0}
          onClick={handleBack}
          style={{ flex: 1, maxWidth: 200 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={handleNext}
          style={{ flex: 1, maxWidth: 200 }}
        >
          {activeStep === steps.length ? 'Finish' : 'Next'}
        </Button>
      </>
    )
  };

  const renderActiveStepText = () => {
    const activeStepText = texts[activeStep];

    if (typeof activeStepText === 'string') {
      return <Typography dangerouslySetInnerHTML={{ __html: texts[activeStep]}}/>
    }

    return activeStepText
  };

  return (
    <Grid container spacing={0} alignItems="stretch" className={classes.root}>
      <Hidden smDown>
        <Grid item xs={12} md={6} className={classes.wizardContext}>
          <Grid container justify="center" className={classes.logoContainer}>
            <Grid item>
              <img
                src={LogoWhite}
                alt="Odyssey by Heidrick & Struggles"
                className={classes.logo}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center" className={classes.wizardText}>
            <Grid item className={classes.wizardTextContent}>
              <Typography variant="h3" style={{ marginBottom: '32px' }}>{headers[activeStep]}</Typography>
              {renderActiveStepText()}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6} className={classes.wizard}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          justify="space-between"
          alignItems="stretch"
          style={{ height: '100%' }}
        >
          {activeStep > 0 && (
            <Grid item style={{ flex: 0 }}>
              <div className={classes.stepperContainer}>
                <Stepper steps={steps} activeStep={activeStep - 1} />
              </div>
            </Grid>
          )}
          <Grid item style={{ flex: 1 }}>
            {getStepContent(activeStep)}
          </Grid>
          {(activeStep < steps.length + 1) && (
            <Grid item style={{ flex: 0, position: 'sticky', bottom: 0, zIndex: 1, background: 'white' }}>
              <Grid container justify="space-around" style={{ padding: '40px 20px' }}>
                {renderStepButtons()}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Onboarding
